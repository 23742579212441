import React, { useState } from "react";
import Input from "../../../../../../client/components/Input";
import { styled } from "../../../../../../client/styles/theme";
import { Invitee } from "../../../../../../shared/Models/RoamInvitations";
import { SendInviteRequest } from "../../../../../../shared/SocialSearchMessages/SocialServerMessages";
import SharedSubmissionForm from "./SharedSubmissionForm";

interface Props {
  personId: number;
  remainingInvites: number;
  onSubmit?: (sendInviteRequest: SendInviteRequest) => void;
  onCancel?: () => void;
}

/**
 * Page for submitting a new referral.
 */
const NewReferralSubmission: React.FC<Props> = (props) => {
  const { personId, remainingInvites, onCancel = () => {}, onSubmit = () => {} } = props;

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [company, setCompany] = useState<string>("");

  const invitee: Invitee = {
    name,
    email,
    company,
  };

  return (
    <Container>
      <SharedSubmissionForm
        personId={personId}
        remainingInvites={remainingInvites}
        invitee={invitee}
        onSubmit={onSubmit}
        onCancel={onCancel}
      >
        <ReferralFields>
          <div>
            <FieldInput
              type={"text"}
              value={name}
              required
              placeholder={"Name"}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <FieldInput
              type={"email"}
              value={email}
              required
              placeholder={"Email"}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <FieldInput
              type={"text"}
              value={company}
              required
              placeholder={"Company"}
              onChange={(e) => setCompany(e.target.value)}
            />
          </div>
        </ReferralFields>
      </SharedSubmissionForm>
    </Container>
  );
};
export default NewReferralSubmission;

const Container = styled.div`
  // background-color: ${({ theme }) => theme.color.background.visualization};

  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;

  margin: 0 0.75rem 0.75rem 0.75rem;

  width: 100%;
`;
Container.displayName = "Container";

const ReferralFields = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;
ReferralFields.displayName = "ReferralFields";

const FieldName = styled.div``;
FieldName.displayName = "FieldName";

const FieldInput = styled(Input)`
  width: 100%;
`;
FieldInput.displayName = "FieldInput";
