import React from "react";
import { baseRoamURL } from "../../../../../../../common/api/http";
import ProfilePicture from "../../../../../../client/components/profile/ProfilePicture";
import { styled } from "../../../../../../client/styles/theme";
import { defaultOccupantPhotoUrl } from "../../../../../../shared/helpers/assets";
import {
  InvitationStatus,
  PendingInvitation,
} from "../../../../../../shared/Models/RoamInvitations";

interface Props {
  invite: PendingInvitation;
}

const displayInvitationStatus = (status: InvitationStatus | undefined): string => {
  switch (status) {
    case "SENT":
    case "TOUR_SCHEDULED":
      return "PENDING";
    default:
      // These pending invites are not intended to be displayed.
      return "";
  }
};

/**
 * A card displaying info about an invite that the user has already made.
 */
const PendingInviteCard: React.FC<Props> = (props) => {
  const { invite } = props;

  const { invitee, status } = invite;

  const image = invitee.profilePhoto ?? defaultOccupantPhotoUrl(baseRoamURL, "Member") ?? "";

  const displayStatus = displayInvitationStatus(status);

  // Lots of stuff copied from InviteSuggestionBase...
  // Could potentially be refactored to some component that rendered some customizable component.
  return (
    <Container>
      <ProfilePic imageSrc={image} displayName={"none"} />
      <Invite>
        <Name>{invitee.name}</Name>
        <Company>{invitee.company}</Company>
      </Invite>
    </Container>
  );
};
export default PendingInviteCard;

const Container = styled.div`
  // background-color: ${({ theme }) => theme.color.background.visualization};

  display: flex;
  flex-direction: row;
  column-gap: 0.75rem;
  align-items: center;

  padding: 0.75rem;
`;
Container.displayName = "Container";

const ProfilePic = styled(ProfilePicture)`
  align-self: center;

  width: 2.5rem;
  aspect-ratio: 1/1;
`;
ProfilePic.displayName = "ProfilePic";

const Invite = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: column;
`;
Invite.displayName = "Invite";

const Name = styled.div`
  ${({ theme }) => theme.font.subheadline};
`;
Name.displayName = "Name";

const Company = styled.div`
  ${({ theme }) => theme.font.subheadline};
  color: ${({ theme }) => theme.color.text.secondary};
`;
Company.displayName = "Roam";

const Status = styled.div`
  display: inline-block;

  color: ${({ theme }) => theme.color.text.accentedPrimary};
  border: 1.5px solid;
  border-radius: 500rem;
  padding: 0.2rem 0.5rem;

  ${({ theme }) => theme.font.captionEmphasis};
`;
Status.displayName = "Status";
