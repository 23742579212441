import React from "react";
import { styled } from "../../../../../../client/styles/theme";
import { InviteSuggestion } from "../../../../../../shared/Models/RoamInvitations";
import { SendInviteRequest } from "../../../../../../shared/SocialSearchMessages/SocialServerMessages";
import InviteSuggestionBase from "./InviteSuggestionBase";
import SharedSubmissionForm from "./SharedSubmissionForm";

interface Props {
  personId: number;
  remainingInvites: number;
  suggestion: InviteSuggestion;
  onSubmit?: (sendInviteRequest: SendInviteRequest) => void;
  onCancel?: () => void;
}

/**
 * Page for submitting a referral, based off of an invite suggestion.
 */
const InviteSuggestionSubmission: React.FC<Props> = (props) => {
  const {
    personId,
    remainingInvites,
    suggestion,
    onCancel = () => {},
    onSubmit = () => {},
  } = props;

  return (
    <Container>
      <InviteSuggestionBase suggestion={suggestion} />
      <SharedSubmissionForm
        personId={personId}
        remainingInvites={remainingInvites}
        invitee={suggestion.invitee}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </Container>
  );
};
export default InviteSuggestionSubmission;

const Actions = styled.div`
  align-self: center;
`;
Actions.displayName = "Actions";

const Container = styled.div`
  // background-color: ${({ theme }) => theme.color.background.visualization};

  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;

  padding: 0.75rem;
`;
Container.displayName = "Container";
