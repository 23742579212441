import { Md5 } from "ts-md5";
import { baseDingDongURL } from "../../../common/api/http";
import { OccupantType } from "../Models/Occupant";

export const defaultPersonPhotoUrl = (baseRoamUrl: string): string => {
  const fileName = "member.png";
  return resolvePresetPersonPhotoUrl(baseRoamUrl, fileName);
};

export const defaultOccupantPhotoUrl = (
  baseRoamUrl: string,
  occupantType: OccupantType | undefined
): string | undefined => {
  if (!occupantType) return;
  const fileName =
    occupantType === "Member"
      ? "member.png"
      : occupantType === "TeleRoom"
      ? "teleroom.png"
      : "visitor.png";
  return resolvePresetPersonPhotoUrl(baseRoamUrl, fileName);
};

export const resolvePresetPersonPhotoUrl = (baseRoamUrl: string, fileName: string): string => {
  return `${baseRoamUrl}/photos/people/${fileName}`;
};

export const defaultAudioEntranceUrl = (
  baseRoamUrl: string,
  occupantType: OccupantType | undefined
): string | undefined => {
  if (!occupantType) return;
  const fileName = occupantType === "Member" ? "jurassic-park-theme-song.mp3" : "superroam.mp3";
  return resolvePresetAudioUrl(baseRoamUrl, fileName);
};

export const resolvePresetAudioUrl = (baseUrl: string, fileName: string): string => {
  return `${baseUrl}/audio/${fileName}`;
};

export const resolveAbsoluteRoamPhotoUrl = (baseRoamUrl: string, fileName: string): string => {
  return `${baseRoamUrl}/card-images/${fileName}`;
};

export const resolveAbsolutePersonPhotoUrl = (
  fileName: string | undefined,
  baseRoamURL: string
): string | undefined => {
  if (fileName && fileName !== DEFAULT_ASSET_NAME) {
    if (isUUID(fileName)) {
      return resolveAbsoluteRoamPhotoUrl(baseRoamURL, fileName);
    } else {
      // TODO(jane): Keep this for now to not break people's old profile photos.
      return resolvePresetPersonPhotoUrl(baseRoamURL, fileName);
    }
  }
};

/**
 * Returns the hash used to construct gravatar-style URLs for DingDongs.
 */
export const dingDongHash = (principal: string): string => {
  return Md5.hashStr(principal);
};

export const dingDongAudioUrl = (hash: string): string => {
  return `${baseDingDongURL}/dd/${hash}`;
};

export const dingDongHashToName = (hash: string): string => {
  return `dingdong-${hash}`;
};

export const dingDongNameToHash = (id: string): string => {
  return id.substring("dingdong-".length);
};

export const isDingDongName = (value: string): boolean => {
  return value.startsWith("dingdong-");
};

export const DEFAULT_ASSET_NAME = "DEFAULT";
export const NONE_ASSET_NAME = "NONE";

export const isUUID = (value: string): boolean => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(value);
};
