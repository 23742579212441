import { z } from "zod";
import { UserRole } from "./UserRole";
import { numberId, stringDate, stringId, uuid } from "./zodTypes";

export const OccupantType = z.enum([
  /** An occupant that is a member of the roam that they are in. */
  "Member",
  /** An occupant that is visiting someone in the roam that they are in. */
  "Visitor",
  /** An "anonymous" occupant that entered the roam via an access link. */
  "AdHoc",
  /** A RoamHQ employee who has superuser-esque privileges, including being able to enter any roam. */
  "Roamgineer",
  /** A teleconferencing room, for kiosk mode clients to join as*/
  "TeleRoom",
]);
export type OccupantType = z.infer<typeof OccupantType>;

/**
 * This Occupant is sent to clients. Any data that should only be exchanged
 * among servers should be added in `InternalOccupant` or `InternalOccupant`,
 * which extend this client-facing version.
 */
export const Occupant = z.object({
  id: stringId(),
  roamId: numberId(),
  accountId: numberId().optional(), // TODO(jane): Make required.
  occupantType: OccupantType,
  givenMemberAccess: z.boolean().optional(),
  userRole: UserRole.optional(),
  connectionId: stringId(),
  name: z.string().optional(),
  email: z.string().optional(),
  personId: numberId().optional(),
  imageName: z.string().optional(), // Used for updating
  imageAbsoluteUrl: z.string().url().optional(),
  audioEntranceName: z.string().optional(), // Used for updating
  audioEntranceAbsoluteUrl: z.string().optional(),
  audioExitName: z.string().optional(), // Used for updating
  audioExitAbsoluteUrl: z.string().optional(),
  hostId: numberId().optional(),
  hostName: z.string().nonempty().optional(),
  hostEmail: z.string().email().optional(),
  accessLinkId: stringId().optional(),
  checkinTime: stringDate().optional(),
  roamaniacId: numberId().optional(),
  physicalOfficeId: uuid().optional(),
  forceTurnTLS: z.boolean().optional(),
  isAvailable: z.boolean().optional(),
  internal: z.literal(false),
  canEditFloors: z.boolean().optional(),
  teleRoomId: uuid().optional(),
});
export type Occupant = z.infer<typeof Occupant>;

export const isMemberOrHasMemberAccess = (o: Occupant | undefined): boolean => {
  return o !== undefined && (o.occupantType === "Member" || o.givenMemberAccess === true);
};

export const isTeleRoom = (o: Occupant | undefined): boolean => {
  return o?.teleRoomId !== undefined;
};

export const isMemberOrRoamgineer = (o: Occupant | undefined): boolean => {
  return (
    o !== undefined &&
    (o.occupantType === "Member" ||
      o.occupantType === "Roamgineer" ||
      o.occupantType === "TeleRoom")
  );
};

export const OccupantCheckInFailureResult = z.enum([
  "billingIssue",
  "error",
  "hostNotAvailable",
  "linkInactive",
  "mustUpgrade",
  "tooManyVisitors",
  "unauthorized",
]);
export type OccupantCheckInFailureResult = z.infer<typeof OccupantCheckInFailureResult>;
export const OccupantCheckInResult = z.union([
  OccupantCheckInFailureResult,
  z.literal("occupantCheckedIn"),
]);
export type OccupantCheckInResult = z.infer<typeof OccupantCheckInResult>;
