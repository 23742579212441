import React, { useState } from "react";
import Button from "../../../../../../client/components/Button";
import TabBar from "../../../../../../client/components/TabBar";
import { keyframes, styled } from "../../../../../../client/styles/theme";
import IconDismiss from "../../../../../../icons/IconDismiss.svg";
import {
  InviteSuggestion,
  PendingInvitation,
} from "../../../../../../shared/Models/RoamInvitations";
import { SendInviteRequest } from "../../../../../../shared/SocialSearchMessages/SocialServerMessages";
import { logger } from "../../../../../../shared/infra/logger";
import InviteList from "./InviteList";
import PendingList from "./PendingList";

// Whether to show:
//  - the invite list/flow
//  - the pending invites
type Mode = "invite" | "pending";

interface Props {
  personId: number;
  inviteSuggestions?: InviteSuggestion[];
  pendingInvites?: PendingInvitation[];
  remainingInvites: number;
  sendInviteRequest?: (request: SendInviteRequest, success?: () => void) => void;
  close?: () => void;
}

/**
 * A "menu" popup that suggests and allows users to invite others to Roam.
 */
const InvitePopup: React.FC<Props> = (props) => {
  const {
    personId,
    inviteSuggestions = [],
    pendingInvites = [],
    remainingInvites,
    sendInviteRequest = () => {},
    close = () => {},
  } = props;

  const [mode, setMode] = useState<Mode>("invite");

  const filteredPendingInvites = pendingInvites.filter((invite) => {
    // Filter to invites that are still pending.
    return invite.status === "SENT" || invite.status === "TOUR_SCHEDULED";
  });

  return (
    <Container>
      <TopBar>
        <WindowControls>
          <Button small variant="ghost" onClick={close}>
            <Button.Icon>
              <IconDismiss />
            </Button.Icon>
          </Button>
        </WindowControls>
        <Title>Express Invites</Title>
      </TopBar>
      {filteredPendingInvites.length > 0 && remainingInvites > 0 && (
        <Tabs>
          <TabBar.Tab selected={mode === "invite"} onClick={() => setMode("invite")}>
            Invites
          </TabBar.Tab>
          <TabBar.Tab selected={mode === "pending"} onClick={() => setMode("pending")}>
            Sent
          </TabBar.Tab>
        </Tabs>
      )}
      <ContentContainer>
        <ScrollableContent>
          {(() => {
            if (mode === "invite" && remainingInvites > 0) {
              return (
                <InviteList
                  personId={personId}
                  remainingInvites={remainingInvites}
                  inviteSuggestions={inviteSuggestions}
                  sendInviteRequest={(request: SendInviteRequest) => {
                    sendInviteRequest(request, () => setMode("pending"));
                  }}
                />
              );
            } else if (mode === "pending" || remainingInvites === 0) {
              return <PendingList personId={personId} pendingInvites={filteredPendingInvites} />;
            } else {
              logger.error(`Invalid mode! ${mode}`);
              return <></>;
            }
          })()}
        </ScrollableContent>
      </ContentContainer>
    </Container>
  );
};
export default InvitePopup;

const Tabs = styled(TabBar)`
  margin: 1rem 0.75rem 0.75rem 0.75rem;
`;
Tabs.displayName = "TabBar";

const TopBar = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
`;
TopBar.displayName = "TopBar";

const WindowControls = styled.div`
  display: flex;
`;
WindowControls.displayName = "WindowControls";

const Title = styled.div`
  ${({ theme }) => theme.font.headline};
  text-align: center;
  width: max-content;
`;
Title.displayName = "Title";

const ContentContainer = styled.div`
  position: relative;
  height: 100vh;
`;

const ScrollableContent = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  overflow-y: auto;
`;

const FadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Container = styled.div`
  animation: ${FadeInAnimation} linear 0.5s;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
