import { transparentize } from "polished";
import backgroundMaterialStyles from "./backgroundMaterials";
import colors, { SemanticColorsType } from "./colors";
import fontStyles from "./fonts";
import { boxShadowStyles, filterDropShadowStyles, textShadowStyles } from "./shadows";
import zIndex from "./zIndex";

const wonderColor = {
  red: "rgb(255, 55, 37)",
  yellow: "rgb(255, 230, 0)",
  green: "rgb(0, 235, 80)",
  cyan: "rgb(32, 179, 255)",
  indigo: "rgb(100, 0, 230)",
  purple: "rgb(210, 0, 230)",
  black: "rgb(0, 0, 0)",
};

const roamColor = {
  citrus: colors.citrus500,
  teal: "rgb(0, 101, 106)",
  indigo: wonderColor.indigo,
  purple: wonderColor.purple,
};

const grayColor = {
  lightest: "rgb(210, 210, 210)",
  light: "rgb(95, 95, 100)",
  medium: "rgb(53, 53, 59)",
  dark: "rgb(30, 30, 35)",
};

// Intentionally use hex notation here, as this value is passed to Electron's BrowserWindow
// backgroundColor property, which does not work with colors in the rgb() notation.
export const windowBackgroundColor = "#111215";

export const makeBaseTheme = (semanticColors: SemanticColorsType) => ({
  background: { ...backgroundMaterialStyles },

  color: {
    ...semanticColors,

    themeOverrides: {
      accent: [
        colors.red500,
        colors.amber500,
        colors.citrus500,
        colors.green500,
        colors.cyan500,
        colors.blue500,
        colors.indigo500,
        colors.pink500,
      ],

      backgroundVisualization: [
        colors.red700,
        colors.amber700,
        colors.citrus700,
        colors.green700,
        colors.cyan700,
        colors.blue700,
        colors.indigo700,
        colors.pink700,
      ],
    },

    background: {
      ...semanticColors.background,
      window: windowBackgroundColor,
      visualization: colors.indigo700,
    },

    wonder: { ...wonderColor },
    gray: { ...grayColor },
    accent: semanticColors.text.accentedPrimary,

    button: {
      filled: {
        background: colors.white,
        color: colors.grey900,
        hoverBackground: colors.white800,
        focusBackground: colors.white700,
        focusBorder: semanticColors.border.focus,
        activeBackground: colors.white700,
        disabledBackground: colors.white200,
        disabledColor: semanticColors.text.disabled,
        progressBackground: colors.black100,

        dangerous: {
          background: colors.red400,
          color: colors.white,
          hoverBackground: colors.red600,
          focusBackground: colors.red500,
          focusBorder: colors.red800,
          activeBackground: colors.red500,
        },

        warning: {
          background: colors.amber400,
          color: colors.grey900,
          hoverBackground: colors.amber600,
          focusBackground: colors.amber500,
          focusBorder: colors.amber800,
          activeBackground: colors.amber500,
        },
      },

      // Outlined and ghost variants use the same style, except ghost does not show a border
      outlined: {
        border: semanticColors.border.secondary,
        color: semanticColors.text.primary,
        hoverBackground: semanticColors.background.secondary,
        focusBorder: semanticColors.border.focus,
        activeBackground: semanticColors.background.secondary,
        disabledColor: semanticColors.text.disabled,
        progressBackground: colors.white200,

        dangerous: {
          border: semanticColors.border.error,
          color: semanticColors.text.error,
          focusBorder: colors.red500,
        },

        warning: {
          border: colors.amber400,
          color: semanticColors.text.warning,
          focusBorder: colors.amber500,
        },

        onLight: {
          border: semanticColors.border.secondaryOnLight,
          color: semanticColors.text.primaryOnLight,
          hoverBackground: colors.black100,
          focusBorder: colors.black,
          activeBackground: colors.black100,
          activeColor: semanticColors.text.primaryOnLight,
          disabledColor: colors.black300,
        },
      },

      // NOTE(jeff): These remaining button color variables are deprecated and should be removed
      primary: semanticColors.text.primary,
      secondary: grayColor.light,
      solidBackground: roamColor.indigo,
    },

    input: {
      background: semanticColors.background.primary,
      color: semanticColors.text.primary,
      placeholderColor: semanticColors.text.secondary,
      focusBackground: semanticColors.background.secondary,
      disabledBackground: semanticColors.background.primary,
      disabledColor: semanticColors.text.disabled,
      borderError: semanticColors.border.error,
    },

    link: {
      color: colors.indigo500,
      activeColor: colors.indigo300,
      visitedColor: colors.indigo300,
    },

    dropdown: {
      background: colors.grey900,
      border: semanticColors.border.primary,

      item: {
        hoverBackground: semanticColors.background.primary,
        activeBackground: semanticColors.background.secondary,
      },
    },

    overworld: {
      planet: {
        primary: {
          background: colors.white,
          color: colors.grey900,
          dot: colors.indigo500,
        },

        secondary: {
          background: semanticColors.background.secondary,
          border: semanticColors.border.primary,
          color: semanticColors.text.primary,
          dot: colors.white100,
        },

        highlight: {
          background: colors.white300,
        },

        placeholder: {
          border: colors.white200,
        },
      },
    },

    elevator: {
      default: semanticColors.text.secondary,
      selected: semanticColors.text.accentedPrimary,
      hoverBackground: semanticColors.background.primary,
    },

    map: {
      room: {
        background: semanticColors.background.primary,
        miniMapBackground: colors.white100,
        border: semanticColors.border.primary,
        myRoomBorder: semanticColors.border.accented,

        editHandle: colors.white,
      },

      theater: {
        stageBackground: colors.white100,
        sectionBorder: semanticColors.border.primary,
        sectionDots: {
          unoccupied: semanticColors.icon.secondary,
          occupied: semanticColors.icon.primary,
          me: semanticColors.icon.accentedPrimary,
        },
      },

      seat: {
        unavailable: colors.white200,
        available: semanticColors.text.secondary,
        availableHoverBackground: colors.white100,
      },

      seatName: {
        background: colors.black800,
        border: colors.white200,
      },

      icon: {
        default: colors.white400,
        hover: colors.white,
        lock: colors.red600,
      },

      overflow: {
        background: colors.grey800,
      },
    },

    miniMap: {
      background: colors.grey900,
    },

    occupant: {
      border: colors.white200,
      followerBorder: colors.red500,

      // These colors are used to indicate occupants, such as with board pointers and shelf focus indicators
      indicators: [
        "rgb(0, 154, 102)",
        roamColor.teal,
        "rgb(0, 48, 152)",
        roamColor.indigo,
        "rgb(159, 0, 239)",
        roamColor.purple,
        "rgb(207, 0, 146)",
        "rgb(190, 0, 48)",
        "rgb(216, 70, 40)",
      ],

      connectionStatus: {
        unknown: colors.grey700,
        muted: colors.grey700,
        failed: semanticColors.icon.error,
        connected: semanticColors.icon.positive,
        connecting: semanticColors.icon.warning,
        border: colors.black,
        borderMuted: semanticColors.icon.error,
      },

      talkingIndicator: {
        inner: colors.white200,
        outer: colors.white200,
      },
    },

    mapGridEditingPattern: transparentize(0.9, semanticColors.text.primary),
    mapRoomContainerEditingBorder: grayColor.light,

    editableShortTextBackground: transparentize(0.67, grayColor.lightest),

    occupantVideo: {
      loadingBackground: colors.white100,
      profileBorder: semanticColors.border.primary,
      talkingIndicator: semanticColors.border.accented,
      overlayBackground: colors.black400,

      networkStatus: {
        medium: semanticColors.icon.warning,
        bad: semanticColors.icon.warning,
        broken: semanticColors.icon.error,
      },
    },

    board: {
      background: semanticColors.background.secondary,
      toolbarBackground: semanticColors.background.secondary,

      card: {
        text: {
          foreground: semanticColors.text.primaryOnLight,
          background: colors.white,
        },

        stream: {
          background: grayColor.light,
        },

        audio: {
          background: colors.black,
        },

        video: {
          background: colors.black,
        },

        topBar: {
          light: {
            foreground: semanticColors.text.primaryOnLight,
            background: colors.white,
            border: semanticColors.border.primaryOnLight,
          },

          dark: {
            foreground: semanticColors.text.primary,
            background: colors.black400,
            border: semanticColors.border.primary,
          },
        },

        footer: {
          border: semanticColors.border.primaryOnLight,
        },
      },
    },

    dock: {
      icon: {
        backgroundFocused: colors.white200,
        background: colors.white100,
        initialsBackground: colors.white50, // match background color from member.png
      },
    },

    knock: {
      popup: {
        background: colors.grey900,
        border: semanticColors.border.primary,
      },
    },

    shelf: {
      background: colors.black200,
      border: colors.white100,

      item: {
        background: semanticColors.background.primary,
        border: semanticColors.border.primary,
        floatingBackdrop: colors.black300,
      },

      remove: {
        background: colors.black800,
        border: colors.white200,
      },
    },

    chat: {
      bubbles: {
        incoming: {
          background: colors.white100,
          color: semanticColors.text.primary,
        },

        outgoing: {
          background: colors.indigo600,
          color: semanticColors.text.primary,
        },
      },

      confidential: {
        background: colors.amber800,
        color: semanticColors.text.primaryOnLight,
      },

      reaction: {
        myBorder: colors.indigo400,
      },
    },

    calendar: {
      nowIndicator: colors.red400,
    },

    infoPopup: {
      background: grayColor.dark,
    },

    userStatusView: {
      background: semanticColors.background.primary,
      online: semanticColors.icon.positive,
    },

    website: {
      scheduleTour: {
        background: colors.white,
        primary: colors.blue500,
      },

      footer: {
        light: {
          background: colors.white,
          divider: colors.grey400,
        },

        dark: {
          background: "#080114",
          divider: semanticColors.border.primary,
        },
      },
    },
  },

  font: { ...fontStyles },

  shadow: {
    box: { ...boxShadowStyles },
    text: { ...textShadowStyles },
    icon: { ...filterDropShadowStyles },
  },

  dimen: {
    border: {
      thin: "0.03125rem",
      default: "0.125rem",
      thick: "0.25rem",
    },

    mapReceptionRoomPaddingLeft: "0rem",
    mapReceptionRoomPaddingRight: "1rem",

    chat: {
      messageMargin: "3rem",
      userStatusViewHeight: "2.75rem",
    },

    sidePanelWidth: "min(18rem, 25vw)",

    tray: {
      height: "4.625rem",

      shelf: {
        item: {
          small: "2.5rem",
          large: "5rem",
        },
      },
    },

    settings: {
      sidebarWidth: "12rem",
    },
  },

  zIndex,
});
