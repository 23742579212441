import React, { PropsWithChildren, useState } from "react";
import Button from "../../../../../../client/components/Button";
import { styled } from "../../../../../../client/styles/theme";
import IconTicket from "../../../../../../icons/IconTicket.svg";
import IconTicketGold from "../../../../../../icons/IconTicketGold.svg";
import { Invitee } from "../../../../../../shared/Models/RoamInvitations";
import { SendInviteRequest } from "../../../../../../shared/SocialSearchMessages/SocialServerMessages";

const VIP_INFO = "We'll fast-track anyone you invite with Express Invites.";

interface Props {
  personId: number;
  remainingInvites: number;
  invitee: Invitee;
  onSubmit?: (sendInviteRequest: SendInviteRequest) => void;
  onCancel?: () => void;
}

/**
 * Shared parts of the submission form.
 */
const SharedSubmissionForm: React.FC<PropsWithChildren<Props>> = ({
  children,
  remainingInvites,
  personId,
  invitee,
  onCancel = () => {},
  onSubmit = () => {},
}) => {
  const [message, setMessage] = useState<string>("");

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const request: SendInviteRequest = {
      personId,
      invitee,
      customMessage: message,
    };
    onSubmit(request);
  };

  return (
    <Form onSubmit={submit}>
      <DividedSection>
        <VipInfo>{VIP_INFO}</VipInfo>
        {children}
      </DividedSection>
      <DividedSection>
        <WaitTimesText>Wait Times</WaitTimesText>
        <WaitlistTimeTable />
      </DividedSection>
      <BottomActions>
        <RemainingInvitesText>
          {remainingInvites} express invite{remainingInvites !== 1 && "s"} remaining
        </RemainingInvitesText>
        <RowButton type="submit">Send</RowButton>
        {/*
        We're Removing this for now because the recommendations list, which this
        would take us back to, is always empty. This will change in the future.

        <RowButton variant={"ghost"} type="button" onClick={onCancel}>
          Cancel
        </RowButton>
        */}
      </BottomActions>
    </Form>
  );
};
export default SharedSubmissionForm;

const WaitlistTimeTable: React.FC = () => {
  return (
    <WaitTimeOptions>
      <WaitTimeOption>
        <OptionTitle>
          <OptionIcon>
            <IconTicket />
          </OptionIcon>
          Standard
        </OptionTitle>
        <OptionWaitTime>91 days</OptionWaitTime>
      </WaitTimeOption>
      <WaitTimeOption>
        <PremiumEffect>
          <OptionTitle>
            <OptionIcon>
              <IconTicketGold />
            </OptionIcon>
            Express Invite
          </OptionTitle>
          <OptionWaitTime>3 days</OptionWaitTime>
        </PremiumEffect>
      </WaitTimeOption>
    </WaitTimeOptions>
  );
};

const DividedSection = styled.div`
  border-bottom: ${({ theme }) => theme.dimen.border.thin} solid
    ${({ theme }) => theme.color.border.primary};
  padding-bottom: 1rem;
  margin-bottom: 1rem;
`;

const WaitTimeOptions = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
`;

const WaitTimeOption = styled.div`
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.color.background.primary};
  width: 50%;
  text-align: center;
  padding: 0.5rem;
`;

const PremiumEffect = styled.div`
  background: linear-gradient(95.56deg, #f6dd91 -0.41%, #c79c1a 102.3%);
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
`;

const OptionTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
`;

const OptionIcon = styled.div`
  margin: auto;
  font-size: 1.25rem;
`;

const OptionWaitTime = styled.div`
  padding: 1.5rem 0;
  font-size: 2.125rem;
  line-height: 2.5rem;
`;

const TextArea = styled.textarea`
  width: 100%;
`;
TextArea.displayName = "TextArea";

const VipInfo = styled.div`
  margin 0.5rem 0;
  ${({ theme }) => theme.font.subheadline};
  color: ${({ theme }) => theme.color.text.secondary};
`;
VipInfo.displayName = "VipInfo";

const WaitTimesText = styled.div`
  ${({ theme }) => theme.font.subheadline};
  color: ${({ theme }) => theme.color.text.secondary};
  text-align: center;
  margin-bottom: 0.5rem;
`;

const RemainingInvitesText = styled.div`
  ${({ theme }) => theme.font.subheadline};
  color: ${({ theme }) => theme.color.text.secondary};
  text-align: center;
`;
RemainingInvitesText.displayName = "RemainingInvitesText";

const BottomActions = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: 0.5rem;
`;
BottomActions.displayName = "BottomActions";

const RowButton = styled(Button)`
  width: 100%;
`;
RowButton.displayName = "RowButton";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
Form.displayName = "Form";
