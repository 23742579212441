import "../../../../../../common/api/loadZoneAppWebEnvironment"; // Load first to set environment, newline to prevent sorting later

import * as React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "../../../../../client/styles/style.css";
import { ThemeProviderWithoutStore } from "../../../../../client/styles/ThemeProviderWithoutStore";
import ResetBackground from "../../../shared/components/ResetBackground";
import ErrorPage from "./ErrorPage";
import InviteApp from "./InviteApp";

const router = createBrowserRouter([
  {
    path: "/",
    // TODO: Root page?
    element: <InviteApp />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/interaction-buttons",
    element: <InviteApp />,
  },
  {
    path: "/overworld-invites",
    // TODO: Overworld root
    element: <InviteApp />,
  },
]);

const rootElement = document.getElementById("root");
if (rootElement) {
  rootElement.style.height = "100vh";
  rootElement.style.display = "flex";
  rootElement.style.flexDirection = "column";

  const root = createRoot(rootElement);

  root.render(
    <ThemeProviderWithoutStore>
      <ResetBackground />
      <RouterProvider router={router} />
    </ThemeProviderWithoutStore>
  );
}
