import ZonePlatformClient from "./ZonePlatformClient";
import { baseRoamURL } from "../../../../../../common/api/http";
import axios, { AxiosInstance, AxiosRequestHeaders } from "axios";
import { ZoneResponderRequest } from "../../../../../shared/ZoneMessages/types";

/**
 * HTTP API implementation of `ZonePlatformClient`.
 */
class ZonePlatformHttpApiClient implements ZonePlatformClient {
  private readonly api: AxiosInstance;

  constructor(zoneToken: string | undefined) {
    const baseUrl = new URL(baseRoamURL);
    baseUrl.pathname = "/zone";

    const headers: AxiosRequestHeaders = {};
    if (zoneToken) {
      headers["Zone-Token"] = zoneToken;
    }

    this.api = axios.create({
      baseURL: baseUrl.href,
      headers,
    });
  }

  public async verifyToken(): Promise<ZoneResponderRequest> {
    const { data } = await this.api.post("/token/verify");
    return data;
  }
}
export default ZonePlatformHttpApiClient;
