/* eslint-disable prefer-arrow/prefer-arrow-functions */
import equal from "fast-deep-equal";
import { isObjectLike } from "lodash";
import * as Reselect from "reselect";
import { RootState } from "../store/reducers";

const myCreateDeepEqualSelector = Reselect.createSelectorCreator(Reselect.defaultMemoize, {
  resultEqualityCheck: equal,
});

export function createDeepEqualSelector<Input, Result>(
  inputSelector: (state: RootState) => Input,
  resultFunc: (input: Input) => Result
): (state: RootState) => Result;

export function createDeepEqualSelector<Input1, Input2, Result>(
  inputSelector1: (state: RootState) => Input1,
  inputSelector2: (state: RootState) => Input2,
  resultFunc: (input1: Input1, input2: Input2) => Result
): (state: RootState) => Result;

export function createDeepEqualSelector<Input1, Input2, Input3, Result>(
  inputSelector1: (state: RootState) => Input1,
  inputSelector2: (state: RootState) => Input2,
  inputSelector3: (state: RootState) => Input3,
  resultFunc: (input1: Input1, input2: Input2, input3: Input3) => Result
): (state: RootState) => Result;

export function createDeepEqualSelector<Input1, Input2, Input3, Input4, Result>(
  inputSelector1: (state: RootState) => Input1,
  inputSelector2: (state: RootState) => Input2,
  inputSelector3: (state: RootState) => Input3,
  inputSelector4: (state: RootState) => Input4,
  resultFunc: (input1: Input1, input2: Input2, input3: Input3, input4: Input4) => Result
): (state: RootState) => Result;

export function createDeepEqualSelector<Result>(...params: any): (state: RootState) => Result {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return myCreateDeepEqualSelector(...params);
}

export function createSelector<Input, Result>(
  inputSelector: (state: RootState) => Input,
  resultFunc: (input: Input) => Result,
  options?: { memoizeOptions: Reselect.DefaultMemoizeOptions }
): (state: RootState) => Result;

export function createSelector<Input1, Input2, Result>(
  inputSelector1: (state: RootState) => Input1,
  inputSelector2: (state: RootState) => Input2,
  resultFunc: (input1: Input1, input2: Input2) => Result,
  options?: { memoizeOptions: Reselect.DefaultMemoizeOptions }
): (state: RootState) => Result;

export function createSelector<Input1, Input2, Input3, Result>(
  inputSelector1: (state: RootState) => Input1,
  inputSelector2: (state: RootState) => Input2,
  inputSelector3: (state: RootState) => Input3,
  resultFunc: (input1: Input1, input2: Input2, input3: Input3) => Result,
  options?: { memoizeOptions: Reselect.DefaultMemoizeOptions }
): (state: RootState) => Result;

export function createSelector<Input1, Input2, Input3, Input4, Result>(
  inputSelector1: (state: RootState) => Input1,
  inputSelector2: (state: RootState) => Input2,
  inputSelector3: (state: RootState) => Input3,
  inputSelector4: (state: RootState) => Input4,
  resultFunc: (input1: Input1, input2: Input2, input3: Input3, input4: Input4) => Result,
  options?: { memoizeOptions: Reselect.DefaultMemoizeOptions }
): (state: RootState) => Result;

export function createSelector(...params: any) {
  if (process.env.NODE_ENV === "production") {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return Reselect.createSelector(...params);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const selector = Reselect.createSelector(...params);

    return (state: RootState) => {
      const value = selector(state);

      // Output an error if the value indicates that the selector may not be memoized.
      if (
        isObjectLike(value) &&
        !Object.isFrozen(value) &&
        !params[params.length - 1]?.memoizeOptions?.resultEqualityCheck
      ) {
        console.error(
          `Selector returned an object which is likely not memoized. Please use createDeepEqualSelector instead.`,
          params
        );
      }

      return value;
    };
  }
}
