import React from "react";
import { styled } from "../../../../../../client/styles/theme";
import Button from "../../../../../../client/components/Button";
import NewReferralBase from "./NewReferralBase";

interface Props {
  onInvite?: () => void;
}

/**
 * An invite card allowing the user to add a new referral.
 */
const NewReferralCard: React.FC<Props> = (props) => {
  const { onInvite = () => {} } = props;

  return (
    <Container>
      <NewReferralBase>
        <Actions>
          <Button onClick={onInvite}>Invite</Button>
        </Actions>
      </NewReferralBase>
    </Container>
  );
};
export default NewReferralCard;

const Container = styled.div`
  // background-color: ${({ theme }) => theme.color.background.visualization};

  padding: 0.75rem;
`;
Container.displayName = "Container";

const Text = styled.div`
  flex-grow: 1;

  ${({ theme }) => theme.font.headline};
`;
Text.displayName = "Text";

const Actions = styled.div``;
Actions.displayName = "Actions";
