import { z } from "zod";
import { getPodId, getSectionNumberFromPodId } from "../helpers/audiencePods";
import { audienceSectionToSectionId, floorToSectionId } from "../helpers/sections";
import { logger } from "../infra/logger";
import { Location } from "./Location";
import { VirtualShelfRenderInfo } from "./VirtualShelf";
import { numberId, stringId } from "./zodTypes";

export const ConversationStatus = z.enum(["loading", "ready", "unavailable", "leaving"]);
export type ConversationStatus = z.infer<typeof ConversationStatus>;

export const ConversationAVStatus = z.object({
  micDisabled: z.boolean().optional(),
  micMuted: z.boolean().optional(),
  cameraDisabled: z.boolean().optional(),
  cameraMuted: z.boolean().optional(),
  leaving: z.boolean().optional(),
  virtualShelfRenderInfo: VirtualShelfRenderInfo.optional(),
});
export type ConversationAVStatus = z.infer<typeof ConversationAVStatus>;

export const HybridAudioStatus = z.object({
  groupId: z.string().optional(),
  micEnabled: z.boolean().optional(),
  speakerEnabled: z.boolean().optional(),
});
export type HybridAudioStatus = z.infer<typeof HybridAudioStatus>;

export const ConversationParticipant = z.object({
  conversationKey: stringId(),
  occupantId: stringId(),
  status: ConversationStatus.optional(),
  avStatus: ConversationAVStatus.optional(),
  hybridAudioStatus: HybridAudioStatus.optional(),
});
export type ConversationParticipant = z.infer<typeof ConversationParticipant>;

export const HybridAudioRequest = z.object({
  groupId: z.string().optional(),
  micRequested: z.boolean().optional(),
  speakerRequested: z.boolean().optional(),
});

export type HybridAudioRequest = z.infer<typeof HybridAudioRequest>;

export type ConversationVideoMode = "large" | "medium" | "thumbnail";

export const ReceptionPlace = z.object({
  kind: z.literal("reception"),
  floorId: numberId(),
});
export type ReceptionPlace = z.infer<typeof ReceptionPlace>;

export const RoomPlace = z.object({
  kind: z.literal("room"),
  floorId: numberId(),
  roomId: numberId(),
});
export type RoomPlace = z.infer<typeof RoomPlace>;

export const StagePlace = z.object({
  kind: z.literal("stage"),
  floorId: numberId(),
  roomId: numberId(),
});
export type StagePlace = z.infer<typeof StagePlace>;

export const BackstagePlace = z.object({
  kind: z.literal("backstage"),
  floorId: numberId(),
  roomId: numberId(),
});
export type BackstagePlace = z.infer<typeof BackstagePlace>;

export const FloorMicPlace = z.object({
  kind: z.literal("floor_mic"),
  floorId: numberId(),
  roomId: numberId(),
});
export type FloorMicPlace = z.infer<typeof FloorMicPlace>;

export const PodPlace = z.object({
  kind: z.literal("pod"),
  floorId: numberId(),
  roomId: numberId(),
  podId: numberId(),
});
export type PodPlace = z.infer<typeof PodPlace>;

export const ConversationPlace = z.discriminatedUnion("kind", [
  ReceptionPlace,
  RoomPlace,
  StagePlace,
  BackstagePlace,
  FloorMicPlace,
  PodPlace,
]);
export type ConversationPlace = z.infer<typeof ConversationPlace>;

export const ConversationSummary = z.object({
  place: ConversationPlace,
  participants: ConversationParticipant.array(),
  talkingOccupantIds: stringId().array(),
});
export type ConversationSummary = z.infer<typeof ConversationSummary>;

export const conversationKey = (place: ConversationPlace): string => {
  switch (place.kind) {
    case "reception":
      return `reception-${place.floorId}`;
    case "room":
      return `room-${place.roomId}`;
    case "stage":
      return `stage-${place.roomId}`;
    case "backstage":
      return `backstage-${place.roomId}`;
    case "floor_mic":
      return `floor-mic-${place.roomId}`;
    case "pod":
      return `pod-${place.roomId}-${place.podId}`;
  }
};

/**
 * Return an audienceSectionNumber if place is a pod type
 * Otherwise return the sectionId of the floor (which currently
 * include stage and backstage as part of the floor and
 * floor section server.
 *
 * @param place - a ConversationPlace
 */
export const conversationPlaceToSectionId = (place: ConversationPlace): string => {
  if (place.kind === "pod") {
    return audienceSectionToSectionId(
      place.floorId,
      place.roomId,
      getSectionNumberFromPodId(place.podId)
    );
  }
  return floorToSectionId(place.floorId);
};

/**
 * Return a roomId based on a conversation place.
 * Note that reception returns 'undefined' as the roomId
 *
 * @param place
 */
export const conversationPlaceToRoomId = (
  place: ConversationPlace | undefined
): number | undefined => {
  if (place === undefined || place.kind === "reception") {
    return undefined;
  }
  return place.roomId;
};
/**
 * Return a conversationPlace from a location.
 * If the location is audience then the return type is pod
 *
 * If the location is Backstage then a STAGE conversation Id is returned (one
 * conversation per stage; one backstage per room) -- stage and conversation will
 * share the same conversation to facilitate smooth transitions
 *
 * If the conversation is Room, we check if it's a stage or normal room
 * (this should be replaced with StageLocation logic soon)
 * And return the roomId and floorId in the conversationPlace
 *
 * Finally, reception is one-per-floor so the floorId is returned
 * in the conversationKey
 *
 * @param location
 * @param roomIsAuditorium
 */
export const conversationPlaceFromLocation = ({
  location,
  roomIsAuditorium,
}: {
  location: Location | undefined;
  roomIsAuditorium: boolean;
}): ConversationPlace | undefined => {
  if (!location) return undefined;

  if (location.kind === "AudienceLocation") {
    const podId = getPodId({
      positionNumber: location.positionNumber,
      sectionNumber: location.section.sectionNumber,
    });
    if (podId !== undefined) {
      return {
        kind: "pod",
        floorId: location.section.floorId,
        roomId: location.section.roomId,
        podId,
      };
    } else return undefined;
  } else if (location.kind === "RoomLocation") {
    if (location.subkind === "BackstageLocation") {
      return { kind: "backstage", floorId: location.section.floorId, roomId: location.roomId };
    } else if (location.subkind === "StageLocation") {
      return { kind: "stage", floorId: location.section.floorId, roomId: location.roomId };
    } else if (location.subkind === "FloorMicLocation") {
      return { kind: "floor_mic", floorId: location.section.floorId, roomId: location.roomId };
    } else if (location.subkind === "InvisibleObserverLocation") {
      return undefined;
    } else if (roomIsAuditorium === false) {
      return { kind: "room", floorId: location.section.floorId, roomId: location.roomId };
    } else {
      // If a RoomLocation but we weren't told whether it's an auditorium, we don't know the ConversationPlace
      logger.error("Location error. Room subkind missing for conversationPlace in auditorium");
      return undefined;
    }
  } else if (location.kind === "ReceptionLocation") {
    return { kind: "reception", floorId: location.section.floorId };
  }
  return undefined;
};
