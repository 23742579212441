import axios, { AxiosInstance, AxiosRequestHeaders } from "axios";
import { baseRoamURL } from "../../../../../../common/api/http";
import {
  GetInviteSuggestionsRequest,
  GetInviteSuggestionsResponse,
  GetPendingInvitationsRequest,
  GetPendingInvitationsResponse,
  GetRemainingInvitationsRequest,
  GetRemainingInvitationsResponse,
  SendInviteRequest,
} from "../../../../../shared/SocialSearchMessages/SocialServerMessages";
import SocialServerClient from "./SocialServerClient";

// TODO: Probably move this to social server itself?
/**
 * Social Server HTTP API calls.
 */
class SocialServerHttpClient implements SocialServerClient {
  private readonly api: AxiosInstance;

  constructor(zoneToken: string | undefined) {
    // Endpoints located at: roam/social/src/controllers/external/api
    const baseUrl = new URL(baseRoamURL);
    baseUrl.pathname = "/social-external/api/invites-app";

    const headers: AxiosRequestHeaders = {};
    if (zoneToken) {
      headers["zone-token"] = zoneToken;
    }

    this.api = axios.create({
      baseURL: baseUrl.href,
      headers,
    });
  }

  public async fetchPendingInvitations(
    request: GetPendingInvitationsRequest
  ): Promise<GetPendingInvitationsResponse> {
    const { data } = await this.api.post<GetPendingInvitationsResponse>("pending-invites", request);
    return data;
  }

  public async fetchInviteSuggestions(
    request: GetInviteSuggestionsRequest
  ): Promise<GetInviteSuggestionsResponse> {
    const { data } = await this.api.post<GetInviteSuggestionsResponse>(
      "invite-suggestions",
      request
    );
    return data;
  }

  public async fetchRemainingInvitations(
    request: GetRemainingInvitationsRequest
  ): Promise<GetRemainingInvitationsResponse> {
    const { data } = await this.api.post<GetRemainingInvitationsResponse>(
      "remaining-invites",
      request
    );
    return data;
  }

  public async sendInvite(request: SendInviteRequest): Promise<void> {
    await this.api.post("send-invite", request);
  }
}

export default SocialServerHttpClient;
