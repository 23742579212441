import { datadogLogs, StatusType } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import pino from "pino";

import { environment } from "../api/http";

// USAGE
// Create a logger.ts for your app, which creates and exports a logger instance.
// export const logger = createLogger("serviceName");

// https://github.com/pinojs/pino-pretty
const prettyPrintSettings = environment === "local" ? { colorize: true } : false;

let logLevel: string;
switch (environment) {
  case "local":
    logLevel = "trace";
    break;
  case "exp":
  case "dev":
  case "playground":
    logLevel = "debug";
    break;
  case "stage":
  case "prod":
    logLevel = "info";
    break;
  default:
    throw new Error("Unexpected environment!");
}

export const loggers: Map<string, pino.Logger> = new Map();

let logMethodOverride: pino.LogFn;
export const setLogMethodOverride = (
  overrideMethod: <T extends object>(obj: T, msg?: string, ...args: any[]) => void
) => {
  logMethodOverride = overrideMethod;
};

export const createLogger = (
  name: string,
  discardLogs = false,
  getContextFields?: () => Record<string, any>
) => {
  const options: pino.LoggerOptions = {
    name: name,
    level: logLevel,
    prettyPrint: prettyPrintSettings,
    formatters: {
      level: (label: string, _: number) => ({ level: label }),
    },
    hooks: {
      // Right now error function is only set in
      // Roam Electron, but this makes it so logger.error and
      // logger.fatal in the main process can send
      // an IPC to the logger window to log the error
      logMethod(inputArgs, method, level) {
        if (discardLogs) {
          return;
        }

        let cleanedInputArgs: [object, string, ...any[]];

        const contextFields = getContextFields ? getContextFields() : {};
        /**
        The function sig for the log function is tricky; this will try to normalize the args.

        eg: logger.info({}), logger.info({}, "string"), logger.info("string") are all valid

        Here we're making everything logger.info({}, string) before passing the arg along to
        the main log function

        */
        if (typeof inputArgs[0] === "string") {
          cleanedInputArgs = [contextFields, inputArgs[0], ...inputArgs.slice(1)];
        } else if (typeof inputArgs[0] === "object") {
          let msg: string = "No log message provided";
          let sliceDepth: number = 1;
          if (typeof inputArgs[1] === "string") {
            msg = inputArgs[1];
            sliceDepth = 2;
          }

          cleanedInputArgs = [
            {
              ...contextFields,
              ...inputArgs[0],
            },
            msg,
            ...inputArgs.slice(sliceDepth),
          ];
        } else {
          cleanedInputArgs = [{}, "no log message provided"];
        }

        if (logMethodOverride) {
          // This is only used in the electron main process to send it to the logging
          // window where it will be sent on to data dog and we need the level to propagate
          cleanedInputArgs[0] = {
            ...cleanedInputArgs[0],
            level,
          };
          // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-2.html#caveats
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          logMethodOverride.apply(this, cleanedInputArgs);
        }

        // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-2.html#caveats
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        method.apply(this, cleanedInputArgs);
      },
    },
    // This causes logger.[warn|error|fatal] calls
    // in browser side code to send to datadog if it is configured
    browser: {
      // Add datadogIgnore to these messages to prevent sending them twice
      write: {
        trace: (obj) => {
          const logObj = obj as { msg: any };
          console.trace(logObj.msg, "datadogIgnore");
        },
        debug: (obj) => {
          const logObj = obj as { msg: any };
          console.debug(logObj.msg, "datadogIgnore");
        },
        info: (obj) => {
          const logObj = obj as { msg: any };
          console.info(logObj.msg, "datadogIgnore");
        },
        warn: (obj) => {
          const logObj = obj as { msg: any };
          console.warn(logObj.msg, "datadogIgnore");
        },
        error: (obj) => {
          const logObj = obj as { msg: any };
          console.error(logObj.msg, "datadogIgnore");
        },
      },
      transmit: {
        level: "info",
        send: (level, logEvent) => {
          let logMessage = "";
          let logPayload = {};
          if (logEvent.messages.length === 1) {
            logMessage = logEvent.messages[0] as string;
          } else if (logEvent.messages.length === 2) {
            logMessage = logEvent.messages[1] as string;
            logPayload = logEvent.messages[0] as object;
          }

          if (logMessage) {
            datadogLogs.logger.log(logMessage, logPayload, logEvent.level.label as StatusType);

            if (level === "error") {
              datadogRum.addError(new Error(logMessage), logPayload);
            }
          }
        },
      },
    },
  };
  const logger = discardLogs
    ? pino(options, {
        write: (_: string) => {},
      })
    : pino(options);
  loggers.set(name, logger);
  return logger;
};

// Please use this logger only for common/* files.
// Apps should create and use their own logger instance.
export const commonLogger = createLogger("common");

export const beforeDataDogSend = (log: any) => {
  const str = JSON.stringify(log);
  if (
    /* Errors that can be safely ignored */
    str.includes("ResizeObserver loop limit exceeded") ||
    str.includes("The operation could not be performed and was aborted") ||
    str.includes("Requested device not found") ||
    str.includes("Possible EventEmitter") ||
    str.includes("datadogIgnore")
  ) {
    return false;
  }
};
