import React from "react";
import { styled } from "../../../../../../client/styles/theme";
import { PendingInvitation } from "../../../../../../shared/Models/RoamInvitations";
import PendingInviteCard from "./PendingInviteCard";

interface Props {
  personId: number;
  pendingInvites?: PendingInvitation[];
}

/**
 * Pending invites that the user has submitted.
 */
const PendingList: React.FC<Props> = (props) => {
  const { pendingInvites = [] } = props;

  return (
    <Container>
      {pendingInvites.map((invite) => (
        <PendingInviteCard key={invite.id} invite={invite} />
      ))}
    </Container>
  );
};
export default PendingList;

const Container = styled.div``;
Container.displayName = "Container";
