import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { roamHttp } from "../../../../../common/api/http";
import { isWonderEmail } from "../../../../../common/email/IsWonderEmail";
import { AuthedPerson } from "../../../../shared/Models/AccessibleRoams";
import { Account } from "../../../../shared/Models/accounts/Account";
import { Period } from "../../../../shared/Models/billing/common/time/Period";
import { ExternalActiveMemberPeriodUsageSummary } from "../../../../shared/Models/billing/usage/ExternalActiveMemberPeriodUsageSummary";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux";
import { ConnectionStatus } from "../../../messaging/interfaces/IMessaging";
import { updateInternal, WantHave } from "../../../store/clientConnectionHelpers";
import { RootState } from "../../../store/reducers";
import { WindowType } from "../../../Window";

export interface EmailToken {
  email: string;
  jwt: string;
}

export interface TeleRoomToken {
  teleRoomId: string;
  jwt: string;
}

export interface LoginResult {
  emailTokens: Array<EmailToken>;
  teleRoomToken?: TeleRoomToken;
}

export interface AuthInfoPayload {
  authedPeople: Array<AuthedPerson>;
  confirmedEmails: Array<string>;
  confirmedTeleRoomId?: string;
}

export interface AwayStatus {
  autoActive?: boolean;
  locked?: boolean;
  sleeping?: boolean;
}

export interface StartAddAccountPayload {
  accessLinkQuery?: string; // Needed for web to return to the floor link landing page.
  /**
   * If specified, will attempt to pass through args to auto-launch Roam on the desired platform
   * upon successful authentication.
   *
   * This is intended for flows where the user has to log in at some point during the flow, and it
   * would be mildly annoying for us not to know where the user left off.
   *
   * Currently only supported for access links and platform "web".
   */
  autoLaunchAccessLink?: boolean;

  // Where to show toast for any errors
  windowType?: WindowType;
}

export type ActiveMemberUsageSummaryRequest = {
  requestId: string;
  accountId: number;
  period: Period;
};

export type ActiveMemberUsageSummaryResult = {
  requestId: string;
  summary: ExternalActiveMemberPeriodUsageSummary | undefined;
  status: "SUCCESS" | "FAILED" | "IN_PROGRESS";
};

// TODO: This doesn't get synced/updated yet.
const adapter = createEntityAdapter<Account>();

const activeMemberUsageSummaryAdapter = createEntityAdapter<ActiveMemberUsageSummaryResult>({
  selectId: (result) => result.requestId,
});

const slice = createSlice({
  name: "account",
  initialState: {
    accounts: adapter.getInitialState(),

    emailTokens: new Array<EmailToken>(),
    teleRoomToken: undefined as TeleRoomToken | undefined,

    // follows patterns in docs/clientConnections.md
    wantMessagingStatus: "notConnected" as ConnectionStatus,
    internalWantMessagingStatus: "notConnected" as ConnectionStatus,
    internalHaveMessagingStatus: "notConnected" as ConnectionStatus,

    messagingConnectionRetryTime: undefined as string | undefined,
    connectionAuthenticationComplete: false,

    authedPeople: new Array<AuthedPerson>(),
    confirmedEmails: new Array<string>(),
    confirmedTeleRoomId: undefined as string | undefined,

    activeJwtToken: undefined as string | undefined,
    sfuRegionProbeAuthToken: undefined as string | undefined,
    // Other
    awayStatus: undefined as AwayStatus | undefined,
    blockSleep: false,

    baseUrl: undefined as string | undefined,
    sfuSuffix: undefined as string | undefined,
    notificationPermission: "default" as NotificationPermission,

    activeMemberUsageSummaries: activeMemberUsageSummaryAdapter.getInitialState(),
  },
  reducers: {
    // *** Top-level - middle-end, credentials, etc. ***

    // follows patterns in docs/clientConnections.md
    setWantMessagingStatus: (state, action: PayloadAction<ConnectionStatus>) => {
      state.wantMessagingStatus = action.payload;
    },
    setInternalMessagingStatus: (state, action: PayloadAction<WantHave<ConnectionStatus>>) => {
      updateInternal(state, "MessagingStatus", action.payload);

      if (action.payload.have !== undefined && action.payload.have !== "connected") {
        // We just lost out connection to the middleend
        // When we reconnect, we'll need to resend the tokens
        state.connectionAuthenticationComplete = false;
      }
    },

    setMessagingConnectRetryTime: (state, action: PayloadAction<string | undefined>) => {
      state.messagingConnectionRetryTime = action.payload;
    },
    setConnectionAuthenticationComplete: (state) => {
      state.connectionAuthenticationComplete = true;
    },
    setConnectionAuthInfo: (state, action: PayloadAction<AuthInfoPayload>) => {
      const { authedPeople, confirmedEmails, confirmedTeleRoomId } = action.payload;
      state.authedPeople = authedPeople;
      state.confirmedEmails = confirmedEmails;
      state.confirmedTeleRoomId = confirmedTeleRoomId;
    },

    // Electron only
    loadSavedCredentials: (_) => {},
    // Electron only - called on completion of login flow or after joining a new roam
    addTokens: (state, action: PayloadAction<LoginResult>) => {
      const { emailTokens, teleRoomToken } = action.payload;
      for (const emailToken of emailTokens) {
        const i = state.emailTokens.findIndex((t) => t.email === emailToken.email);
        if (i >= 0) {
          state.emailTokens.splice(i, 1);
        }
        state.emailTokens.push(emailToken);
      }
      state.teleRoomToken = teleRoomToken;

      // activeJwtToken just needs to be any valid token for legacy image uploads
      if (!state.activeJwtToken) {
        if (emailTokens[0]) {
          state.activeJwtToken = emailTokens[0].jwt;
        } else if (teleRoomToken) {
          state.activeJwtToken = teleRoomToken.jwt;
        }
      }
    },
    fetchAccessibleAccounts: (_) => {},
    fetchAccountByDirectSignupUuid: (_, action: PayloadAction<string>) => {},
    updateAccounts: (state, action: PayloadAction<Account[]>) => {
      adapter.upsertMany(state.accounts, action.payload);
    },

    // Triggers login flow.
    startAddAccount: (_, action: PayloadAction<StartAddAccountPayload | undefined>) => {},
    // Electron only: Triggers login flow when there's no connection.
    startAddInitialAccount: (_) => {},
    sendConnectionTokens: (_) => {},

    logoutAllAccounts: (state) => {
      state.activeJwtToken = undefined;
      state.authedPeople = [];
      state.emailTokens = [];
      state.teleRoomToken = undefined;
      delete roamHttp.defaults.headers.common["Authorization"];
    },

    // this removes all email tokens, leaving a teleRoomToken untouched if it exists
    logoutAllEmails: (state) => {
      state.authedPeople = [];
      state.emailTokens = [];
      state.activeJwtToken = state.teleRoomToken?.jwt;
    },

    removeEmailToken: (state, action: PayloadAction<string>) => {
      const email = action.payload;
      state.emailTokens = state.emailTokens.filter((token) => !token.email.endsWith(email));
    },
    removeTeleRoomToken: (state, action: PayloadAction<void>) => {
      state.teleRoomToken = undefined;
    },
    logoutEmail: (state, action: PayloadAction<string>) => {},
    logoutTeleRoom: (state, action: PayloadAction<void>) => {},

    // Electron only - called after we've loaded tokens from the local secure storage
    setTokens: (state, action: PayloadAction<LoginResult>) => {
      const { emailTokens, teleRoomToken } = action.payload;
      state.emailTokens = emailTokens;
      state.teleRoomToken = teleRoomToken;

      // activeJwtToken just needs to be any valid token for legacy image uploads
      if (!state.activeJwtToken) {
        if (emailTokens[0]) {
          state.activeJwtToken = emailTokens[0].jwt;
        } else if (teleRoomToken) {
          state.activeJwtToken = teleRoomToken.jwt;
        }
      }
    },

    setAway: (state, action: PayloadAction<AwayStatus>) => {
      if (state.awayStatus !== undefined) return;
      state.awayStatus = action.payload;
    },

    setActive: (state, action: PayloadAction<void>) => {
      state.awayStatus = undefined;
    },
    setBlockSleep: (state, action: PayloadAction<boolean>) => {
      state.blockSleep = action.payload;
    },
    setSfuSuffix: (state, action: PayloadAction<string>) => {
      state.sfuSuffix = action.payload;
    },
    setSfuRegionProbeAuthToken: (state, action: PayloadAction<string | undefined>) => {
      state.sfuRegionProbeAuthToken = action.payload;
    },

    // Usage
    // saga
    fetchActiveMemberUsageSummary: (
      state,
      action: PayloadAction<ActiveMemberUsageSummaryRequest>
    ) => {},

    // redux
    setActiveMemberUsageSummaryResult: (
      state,
      action: PayloadAction<ActiveMemberUsageSummaryResult>
    ) => {
      activeMemberUsageSummaryAdapter.setOne(state.activeMemberUsageSummaries, action.payload);
    },
  },
});
export const { actions, reducer } = slice;
export const AccountActions = actions;

const selectSlice = (state: RootState) => state.anyWorld.account;
const activeMemberUsageSummarySelectors = activeMemberUsageSummaryAdapter.getSelectors();

const selectAuthedPeople = (state: RootState) => selectSlice(state).authedPeople;

const selectAuthedRoamgineer = createSelector(selectSlice, (slice) =>
  slice.authedPeople.find((ap) => ap.isRoamgineer)
);

export const selectors = {
  selectById: (accountId: number | undefined) => (state: RootState) =>
    accountId ? state.anyWorld.account.accounts.entities[accountId] : undefined,
  selectJwtToken: (state: RootState) => selectSlice(state).activeJwtToken,
  selectConnectionAuthenticationComplete: (state: RootState) =>
    selectSlice(state).connectionAuthenticationComplete,
  selectAwayStatus: (state: RootState) => selectSlice(state).awayStatus,
  selectAuthedPeople,
  /**
   * Whether or not the client has an authenticated email token or TeleRoom token.
   *
   * This is assumed to never be stale for very long, since refreshing tokens (which should occur
   * with any connection/auth change) should update email tokens if they are invalid.
   */
  selectIsAuthenticated: (state: RootState) =>
    selectSlice(state).confirmedEmails.length > 0 ||
    selectSlice(state).confirmedTeleRoomId !== undefined,
  selectEmailTokens: (state: RootState) => selectSlice(state).emailTokens,
  selectTeleRoomToken: (state: RootState) => selectSlice(state).teleRoomToken,
  selectConfirmedTeleRoomId: (state: RootState) => selectSlice(state).confirmedTeleRoomId,
  selectConfirmedEmails: (state: RootState) => selectSlice(state).confirmedEmails,

  // follows patterns in docs/clientConnections.md
  selectWantMessagingStatus: (state: RootState) => selectSlice(state).wantMessagingStatus,
  selectInternalWantMessagingStatus: (state: RootState) =>
    selectSlice(state).internalWantMessagingStatus,
  selectInternalHaveMessagingStatus: (state: RootState) =>
    selectSlice(state).internalHaveMessagingStatus,
  selectMessagingConnectionRetryTime: (state: RootState) =>
    selectSlice(state).messagingConnectionRetryTime,
  selectSfuSuffix: (state: RootState) => selectSlice(state).sfuSuffix,
  selectSfuRegionProbeAuthToken: (state: RootState) => selectSlice(state).sfuRegionProbeAuthToken,
  selectHasWonderConfirmedEmail: createSelector(selectSlice, (slice) => {
    for (const email of slice.confirmedEmails) {
      if (isWonderEmail(email)) {
        return true;
      }
    }
    return false;
  }),
  selectHasConfirmedEmails: (state: RootState) => selectSlice(state).confirmedEmails.length > 0,
  selectHasConfirmedTeleRoomId: (state: RootState) => !!selectSlice(state).confirmedTeleRoomId,
  selectAuthedRoamgineer,
  selectPrimaryIdentities: createDeepEqualSelector(selectSlice, (slice) =>
    slice.authedPeople.filter((ap) => ap.primaryIdentity)
  ),
  selectActiveMemberUsageSummary: (requestId: string) => (state: RootState) =>
    activeMemberUsageSummarySelectors.selectById(
      selectSlice(state).activeMemberUsageSummaries,
      requestId
    ),
  selectIsAdminForAccountId: defaultMemoize((accountId: number) =>
    createSelector(selectAuthedPeople, selectAuthedRoamgineer, (authedPeople, authedRoamgineer) => {
      // roamgineers always admin
      if (authedRoamgineer) {
        return true;
      }

      // if you have an admin person in this account
      return (
        authedPeople.filter((p) => p.accountId === accountId && p.userRole === "Admin").length > 0
      );
    })
  ),
  selectCanEditFloorsForAccountId: defaultMemoize((accountId: number | undefined) =>
    createSelector(selectAuthedPeople, selectAuthedRoamgineer, (authedPeople, authedRoamgineer) => {
      // roamgineers always admin
      if (authedRoamgineer) {
        return true;
      }

      // if you have an admin person in this account
      return (
        authedPeople.filter(
          (p) => p.accountId === accountId && (p.userRole === "Admin" || p.canEditFloors)
        ).length > 0
      );
    })
  ),
};
export const AccountSelectors = selectors;
