import _ from "lodash";
import { z } from "zod";
import { LinkType } from "./LinkType";

/**
 * Meta configuration about an access link type.
 */
export const LinkConfiguration = z.object({
  type: LinkType,
  /**
   * If true, an access link of this type allows the bearer into Roam.
   * Those access links are used in client infos to specify what the bearer has access to.
   *
   * If false, this link type will not grant authentication.
   * The user must already be authenticated to use the link.
   */
  providesAuthentication: z.boolean().default(false),
  /**
   * If true, an intended use of this link is to directly take the user to a specific place or perform a specific action in Roam.
   *
   * If the link does not provide authentication, this should probably be true.
   */
  isDeepLink: z.boolean().default(false),
});
export type LinkConfiguration = z.infer<typeof LinkConfiguration>;

export const LINK_CONFIGURATIONS: LinkConfiguration[] = [
  {
    type: "meeting",
    providesAuthentication: true,
    isDeepLink: false, // legacy
  },
  {
    type: "personal",
    providesAuthentication: true,
    isDeepLink: false, // legacy
  },
  {
    type: "oneTime",
    providesAuthentication: true,
    isDeepLink: false, // legacy
  },
  {
    type: "chat",
    providesAuthentication: false,
    isDeepLink: true,
  },
  {
    type: "screen",
    providesAuthentication: true,
    isDeepLink: false,
  },
  {
    type: "roamUProf",
    providesAuthentication: true,
    isDeepLink: false,
  },
];
export const LINK_CONFIGURATIONS_BY_TYPE = _.keyBy(LINK_CONFIGURATIONS, "type");
