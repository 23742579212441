import React, { useState } from "react";
import { InviteSuggestion } from "../../../../../../shared/Models/RoamInvitations";
import { SendInviteRequest } from "../../../../../../shared/SocialSearchMessages/SocialServerMessages";
import { logger } from "../../../../../../shared/infra/logger";
import InviteSuggestionCard from "./InviteSuggestionCard";
import InviteSuggestionSubmission from "./InviteSuggestionSubmission";
import NewReferralCard from "./NewReferralCard";
import NewReferralSubmission from "./NewReferralSubmission";

// Max number of displayed invite suggestions at once.
const MAX_INVITE_SUGGESTIONS = 3;

// Whether to show:
//  - the invite suggestion list
//  - the submission form for a particular invite.
type Mode = "list" | "submission";

interface Props {
  personId: number;
  remainingInvites: number;
  inviteSuggestions?: InviteSuggestion[];
  /** Action to trigger when the user submits an invite. */
  sendInviteRequest?: (request: SendInviteRequest) => void;
}

/**
 * A list of people that the user can invite to Roam.
 */
const InviteList: React.FC<Props> = (props) => {
  const {
    personId,
    remainingInvites,
    inviteSuggestions = [],
    sendInviteRequest = () => {},
  } = props;

  const [mode, setMode] = useState<Mode>(inviteSuggestions.length > 0 ? "list" : "submission");

  // The potential referral, displayed in submission mode.
  // undefined is intended to be used for a new referral.
  const [referral, setReferral] = useState<InviteSuggestion | undefined>(undefined);

  const displayInviteSuggestions = inviteSuggestions.slice(0, MAX_INVITE_SUGGESTIONS);

  const selectSuggestionForReferral = (suggestion: InviteSuggestion) => {
    setReferral(suggestion);
    setMode("submission");
  };

  const startNewReferral = () => {
    setReferral(undefined);
    setMode("submission");
  };

  const submitSubmission = (request: SendInviteRequest) => {
    sendInviteRequest(request);
  };

  const cancelSubmission = () => {
    setMode("list");
    setReferral(undefined);
  };

  if (mode === "list") {
    return (
      <>
        {displayInviteSuggestions.map((suggestion) => (
          <InviteSuggestionCard
            key={suggestion.invitee.email}
            suggestion={suggestion}
            onInvite={selectSuggestionForReferral}
          />
        ))}
        <NewReferralCard onInvite={startNewReferral} />
      </>
    );
  } else if (mode === "submission") {
    if (referral === undefined) {
      return (
        <NewReferralSubmission
          personId={personId}
          remainingInvites={remainingInvites}
          onSubmit={submitSubmission}
          onCancel={cancelSubmission}
        />
      );
    } else {
      return (
        <InviteSuggestionSubmission
          personId={personId}
          remainingInvites={remainingInvites}
          suggestion={referral}
          onSubmit={submitSubmission}
          onCancel={cancelSubmission}
        />
      );
    }
  } else {
    logger.error(`Invalid invite mode! ${mode}`);
    return <></>;
  }
};
export default InviteList;
