import { createLogger } from "../../../common/infra/logger";
import { contextLogFields } from "./context/context";

let discardLogs = false;

if (typeof process !== "undefined") {
  discardLogs =
    process.env.WINDOWS_ELECTRON_MAIN === "true" && process.env.NODE_ENV === "production";
}

export const logger = createLogger("roam", discardLogs, () => contextLogFields());
