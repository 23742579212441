import React, { useEffect } from "react";

interface Props {}

/**
 * Resets the body background color to be transparent.
 */
const ResetBackground: React.FC<Props> = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "rgba(0,0,0,0)";
  }, []);

  return <></>;
};
export default ResetBackground;
