import React from "react";
import { styled } from "../../styles/theme";

export interface ProfilePictureProps extends React.HTMLAttributes<HTMLDivElement> {
  imageSrc: string;
  name?: string;
  // Maybe name similar to CSS properties?
  // Auto is supposed to hide the name if the component gets too small, but this is not implemented yet.
  // Default: auto
  displayName?: "auto" | "always" | "none";
}

/**
 * A profile picture, optionally including the name of the displayed profile.
 *
 * Preferably, this component is always composed when you need to show someone's profile image.
 *
 * Please keep this component stateless and effect free.
 */
const ProfilePicture: React.FC<ProfilePictureProps> = (props) => {
  const { imageSrc, name, displayName = "auto", ...rest } = props;

  const showName = displayName === "auto" || displayName === "always";

  return (
    <Container title={name} {...rest}>
      <Image src={imageSrc} />
      {showName && (
        <TextContainer>
          <Text>{name}</Text>
        </TextContainer>
      )}
    </Container>
  );
};
export default ProfilePicture;

const Container = styled.div`
  position: relative;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
`;

// Should font size adjust based on component size?
// TODO: Figure out appropriate values here.
const TextContainer = styled.div`
  width: 100%;
  height: 2rem;
  position: absolute;
  bottom: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 0.75rem;

  background-color: ${({ theme }) => theme.color.gray.medium};
  opacity: 0.7;
`;

const Text = styled.div`
  max-width: 100%;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
