import { Environment } from "../api/environment";
import { environment } from "../api/http";

export const isWonderEmail = (email: string): boolean => {
  if (email.endsWith("@wonder.inc")) {
    return true;
  }
  if (email.endsWith(".wonder.dev")) {
    return true;
  }
  if (email.endsWith("@ro.am")) {
    return true;
  }
  return false;
};

export const isWonderTestEmail = (email: string): boolean => {
  return (
    email.startsWith("testaccount+") &&
    (email.endsWith("@wonder.inc") || email.endsWith(".wonder.dev"))
  );
};

export const isWonderDevEmail = (email: string): boolean => {
  return (
    email.includes("wonder.dev") || email.includes("tokens.horse") || email.includes("dream.horse")
  );
};

export const envEmailDomain: { [env in Environment]: string | undefined } = {
  local: "dev.wonder.dev",
  exp: "exp.wonder.dev",
  dev: "dev.wonder.dev",
  stage: "stage.wonder.dev",
  playground: "playground.wonder.dev",
  prod: undefined,
};

export const prodToEnvEmail = (email: string): string => {
  const envSuffix = envEmailDomain[environment];
  if (envSuffix) {
    return email.replace("wonder.inc", envSuffix);
  }
  return email;
};
