import React, { PropsWithChildren } from "react";
import { styled } from "../../../../../../client/styles/theme";
import IconPlusCircle from "../../../../../../icons/IconPlusCircle.svg";

interface Props {}

/**
 * Base piece for an invite card allowing the user to add a new referral.
 */
const NewReferralBase = (props: PropsWithChildren<Props>) => {
  const { children } = props;

  return (
    <Container>
      <div>
        {/* Styling intended to match InviteSuggestionCard */}
        <IconPlusCircle width={"4rem"} height={"4rem"} />
      </div>
      <Text>Start New Referral</Text>
      <div>{children}</div>
    </Container>
  );
};
export default NewReferralBase;

const Container = styled.div`
  // background-color: ${({ theme }) => theme.color.background.visualization};

  display: flex;
  flex-direction: row;
  column-gap: 0.75rem;
  align-items: center;
`;
Container.displayName = "Container";

const Text = styled.div`
  flex-grow: 1;

  ${({ theme }) => theme.font.headline};
`;
Text.displayName = "Text";
