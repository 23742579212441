import axios from "axios";
import ports from "../../common/ports.json";
import { serverAxiosConfig } from "../server/serverAxios";
import {
  allEnvironments,
  Environment,
  environmentLaunchDarklyClientSideIds,
  environmentMatomoSiteIds,
  isDevMode,
  overrideEnvironment,
} from "./environment";
import {
  crmServiceLocations,
  dingDongServiceLocations,
  listeningServiceLocations,
  mediaServiceLocations,
  oldRoamServiceLocations,
  roamBackendServiceLocations,
  roamWSServiceLocations,
} from "./serviceLocations";

export const environment: Environment =
  overrideEnvironment && allEnvironments.includes(overrideEnvironment)
    ? overrideEnvironment
    : isDevMode
    ? "local"
    : "dev";

export const matomoSiteIdForEnv = environmentMatomoSiteIds[environment];

export const launchDarklyClientIdForEnv = environmentLaunchDarklyClientSideIds[environment];

export const baseListeningURL = listeningServiceLocations[environment];

export const listeningHttp = axios.create({
  ...serverAxiosConfig,
  baseURL: baseListeningURL,
  // TODO: security token
  headers: {
    "Content-Type": "application/json",
  },
  transformResponse: [
    (data): any => {
      if (typeof data === "string") {
        return JSON.parse(data, dateReviver);
      }
      return data;
    },
  ],
});

export const baseMediaURL = mediaServiceLocations[environment];

export const mediaHttp = axios.create({
  ...serverAxiosConfig,
  baseURL: baseMediaURL,
  headers: {
    "Content-Type": "application/json",
  },
  transformResponse: [
    (data): any => {
      if (typeof data === "string") {
        return JSON.parse(data, dateReviver);
      }
      return data;
    },
  ],
});

export const oldRoamURL = oldRoamServiceLocations[environment];

// Allows accessing Roam Middle End from multiple ports locally
export const baseRoamURL =
  typeof window !== "undefined"
    ? window?.location?.hostname?.includes("ngrok")
      ? `https://${window.location.hostname}`
      : window?.location?.hostname === "localhost" && window?.location?.port !== "3002" // electron
      ? `http://localhost:${ports.ROAM_MIDDLEEND_PORT}`
      : roamWSServiceLocations[environment]
    : process.env.ROAM_CLIENT_HOSTNAME
    ? `https://${process.env.ROAM_CLIENT_HOSTNAME}`
    : roamWSServiceLocations[environment];

// This is used when developing locally to set up
// ngrok and should be overridden
// See roam/backend/src/calendar/developer-setup.md
export const baseWebhooksURL =
  typeof process !== "undefined" ? process?.env?.WEBHOOK_BASE_URL ?? baseRoamURL : baseRoamURL;

// Similar to baseWebhooksURL for dev
export const baseOauthURL =
  typeof process !== "undefined" ? process.env.OAUTH_BASE_URL ?? baseRoamURL : baseRoamURL;

export const assetBaseUrl =
  typeof process !== "undefined" ? process.env.ASSET_BASE_URL ?? baseRoamURL : baseRoamURL;

export const baseRoamBackendURL =
  typeof window !== "undefined" && window?.location?.hostname === "localhost"
    ? window.location.origin
    : roamBackendServiceLocations[environment];

export const roamHttp = axios.create({
  ...serverAxiosConfig,
  baseURL: baseRoamURL,
  headers: {
    "Content-Type": "application/json",
  },
  transformResponse: [
    (data): any => {
      if (typeof data === "string") {
        return JSON.parse(data, dateReviver);
      }
      return data;
    },
  ],
});

export const baseDingDongURL =
  typeof window !== "undefined" && window?.location?.hostname === "localhost"
    ? window.location.origin
    : dingDongServiceLocations[environment];

export const baseCrmURL =
  typeof window !== "undefined" && window?.location?.hostname === "localhost"
    ? window.location.origin
    : crmServiceLocations[environment];

// From https://stackoverflow.com/a/3143231
const dateFormat =
  /^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))$/;

export const dateReviver = (_: any, value: unknown) => {
  if (typeof value === "string" && dateFormat.test(value)) {
    return new Date(value);
  }

  return value;
};
