import ports from "../../common/ports.json";
import { Environment } from "./environment";

const serviceLocations = (service: string, localPort: number): { [env in Environment]: string } => {
  let localHostname = "localhost";
  if (
    typeof window !== "undefined" &&
    window.location.href.startsWith("http") &&
    !window.location.hostname.includes(".")
  ) {
    localHostname = window.location.hostname;
  } else if (typeof process !== "undefined" && process.env.LOCAL_HOSTNAME) {
    localHostname = process.env.LOCAL_HOSTNAME;
  }

  const locations = {
    local: `http://${localHostname}:${localPort}`,
    exp: `https://${service}.exp.wonder.dev`,
    dev: `https://${service}.dev.wonder.dev`,
    stage: `https://${service}.stage.wonder.dev`,
    playground: `https://${service}.playground.wonder.dev`,
    prod: `https://${service}.wonder.inc`,
  };

  const frontendHostOverride = typeof process !== "undefined" && process.env.FRONTEND_HOST_OVERRIDE;
  if (frontendHostOverride) {
    if (service === "app" || service === "listening") {
      locations["local"] = frontendHostOverride;
    }
  }

  if (service === "app") {
    locations["exp"] = "https://roam.horse";
    locations["dev"] = "https://roam.dev";
    locations["stage"] = "https://roamstaging.com";
    locations["prod"] = "https://ro.am";
  }

  if (service === "listening") {
    locations["exp"] = "https://listening.roam.horse";
    locations["dev"] = "https://listening.roam.dev";
    locations["stage"] = "https://listening.roamstaging.com";
    locations["prod"] = "https://listening.ro.am";
  }

  return locations;
};

export const starHostname: { [env in Environment]: string } = {
  local: "*.dev.wonder.dev",
  exp: "*.exp.wonder.dev",
  dev: "*.dev.wonder.dev",
  stage: "*.stage.wonder.dev",
  playground: "*.playground.wonder.dev",
  prod: "*.wonder.inc",
};

export const alternateStarHostname: { [env in Environment]: string } = {
  local: "*.roam.dev",
  exp: "*.roam.horse",
  dev: "*.roam.dev",
  stage: "*.roamstaging.com",
  playground: "*.playground.wonder.dev",
  prod: "*.ro.am",
};

// This is still needed for listening for CORS purposes
// as it still serves Dynascore
export const dynascoreServiceLocations = serviceLocations(
  "dynascore",
  ports.DYNASCORE_BACKEND_PORT
);

// Needed for backwards compatibility until
// VERSION_132 is no longer supported.  Should only be
// used for CORS and CSP until then
export const oldRoamServiceLocations = serviceLocations("roam", ports.ROAM_MIDDLEEND_PORT);
export const listeningServiceLocations = serviceLocations(
  "listening",
  ports.LISTENING_BACKEND_PORT
);
export const mediaServiceLocations = listeningServiceLocations;
export const roamWSServiceLocations = serviceLocations("app", ports.ROAM_MIDDLEEND_PORT);
export const roamBackendServiceLocations = serviceLocations("roambackend", ports.ROAM_BACKEND_PORT);
export const roamCalendarServiceLocations = serviceLocations("calendar", ports.ROAM_CALENDAR_PORT);
export const holoServiceLocations = serviceLocations("holo", ports.ROAM_HOLO_PORT);
export const sectionServiceLocations = serviceLocations("section", ports.ROAM_SECTION_PORT_1);
export const roamUserLocationServiceLocations = serviceLocations(
  "userlocation",
  ports.ROAM_USER_LOCATION_PORT
);
export const roamOverworldServiceLocations = serviceLocations(
  "overworld",
  ports.ROAM_OVERWORLD_PORT
);
export const roamChatSearchServiceLocations = serviceLocations(
  "chatsearch",
  ports.ROAM_CHATSEARCH_PORT
);
export const roamTeamRoamServiceLocations = serviceLocations("teamroam", ports.ROAM_TEAM_ROAM_PORT);
export const roamChatServiceLocations = serviceLocations("chat", ports.ROAM_CHAT_PORT);
export const roamChatServerServiceLocations = serviceLocations(
  "chatserver",
  ports.ROAM_CHAT_SERVER_PORT
);
export const roamChatMessagesServerLocations = serviceLocations(
  "chatMessages",
  ports.ROAM_CHAT_MESSAGES_PORT
);
export const roamAddressServerLocations = serviceLocations(
  "addressserver",
  ports.ROAM_ADDRESS_SERVER_PORT
);
export const roamAddressInfoServerLocations = serviceLocations(
  "addressInfo",
  ports.ROAM_ADDRESS_INFO_PORT
);
export const roamAppsServiceLocations = serviceLocations("apps", ports.ROAM_APPS_PORT);
export const roamNotificationServiceLocations = serviceLocations(
  "notification",
  ports.ROAM_NOTIFICATION_PORT
);
export const roamStaticServerServiceLocations = serviceLocations(
  "staticserver",
  ports.ROAM_STATIC_SERVER_PORT
);
export const roamSocialSearchServiceLocations = serviceLocations("social", ports.ROAM_SOCIAL_PORT);
export const roamAutoboardingServiceLocations = serviceLocations(
  "autoboarding",
  ports.ROAM_AUTOBOARDING_PORT
);
export const gorushServiceLocations = serviceLocations("gorush", ports.GORUSH_PORT);

export const dingDongServiceLocations = serviceLocations("dingdong", ports.DINGDONG_BACKEND_PORT);

export const crmServiceLocations = serviceLocations("crm", ports.CRM_BACKEND_PORT);

export const roamZoneServiceLocations = serviceLocations("zone", ports.ROAM_ZONE_PORT);

export const roamBillingServiceLocations = serviceLocations("billing", ports.ROAM_BILLING_PORT);

export const filesServiceLocations = serviceLocations("files", ports.FILES_PORT);

export const allServiceLocations = [
  dynascoreServiceLocations,
  oldRoamServiceLocations,
  roamWSServiceLocations,
  roamBackendServiceLocations,
  holoServiceLocations,
  roamOverworldServiceLocations,
  roamChatServiceLocations,
  roamSocialSearchServiceLocations,
  listeningServiceLocations,
  dingDongServiceLocations,
  crmServiceLocations,
  roamZoneServiceLocations,
  roamBillingServiceLocations,
  roamStaticServerServiceLocations,
  roamCalendarServiceLocations,
  roamAutoboardingServiceLocations,
  filesServiceLocations,
];
