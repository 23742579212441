import React from "react";
import { useRouteError } from "react-router-dom";
import { logger } from "../../../../../shared/infra/logger";

interface Props {}

const ErrorPage: React.FC<Props> = () => {
  const error = useRouteError();
  logger.error({ error });

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
    </div>
  );
};
export default ErrorPage;
