import Color from "color";
import colors from "./colors";

type BackgroundMaterial = "blur" | "confidentialBlur";

const blurMaterialStyles = (backgroundColor: Color<string>) => {
  const alpha = 0.64;
  const blurPx = 24;

  return `
  background-color: ${backgroundColor.string()};

  @supports (backdrop-filter: blur(${blurPx}px)) {
    background-color: ${backgroundColor.alpha(alpha).string()};

    // Placing backdrop-filter inside :before makes nested blurring work
    // A component should not both have a blurred background and also
    // overflow/scroll. If used together, the blur will move with the scroll,
    // and the content initially below the fold will not have a blurred
    // background. This is a known CSS issue, and it can be fixed with
    // position: sticky (amongst other changes), but that doesn't work with
    // the backdrop-filter effect. If both blur and overflow/scroll are needed,
    // have a parent component with the background blur and a child component
    // that overflows/scrolls.
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(${blurPx}px);
      z-index: -999999;
      border-radius: inherit;
    }
  }
  `;
};

const backgroundMaterialStyles: { [backgroundMaterial in BackgroundMaterial]: string } = {
  blur: blurMaterialStyles(Color(colors.grey900)),
  confidentialBlur: blurMaterialStyles(Color("#211301")),
};

export default backgroundMaterialStyles;
