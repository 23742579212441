import { maxPodSize, sectionSize } from "./audienceSections";

interface Props {
  sectionNumber: number | undefined;
  positionNumber: number | undefined;
}

export const getPodId = ({ sectionNumber, positionNumber }: Props): number | undefined => {
  return sectionNumber != null && positionNumber != null
    ? Math.floor((sectionNumber * sectionSize + positionNumber) / maxPodSize)
    : undefined;
};

export const getSectionNumberFromPodId = (podId: number): number => {
  return Math.floor(podId / (sectionSize / maxPodSize));
};
