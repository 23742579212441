import {
  createEntityAdapter,
  createSlice,
  Draft,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import equal from "fast-deep-equal";
import { defaultMemoize } from "reselect";
import { isMemberOrHasMemberAccess } from "../../../../shared/Models/Occupant";
import {
  AccessMode,
  AccessModeDetails,
  Room,
  RoomSeat,
  RoomType,
  Stage,
} from "../../../../shared/Models/Room";
import * as ra from "../../../../shared/Models/RoomActions";
import { ClientRect } from "../../../helpers/dom";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux";
import { RootState } from "../../../store/reducers";
import { WindowType } from "../../../Window";
import { OccupantSelectors } from "../../../world/store/slices/occupantSlice";
import { WorldSelectors } from "../../../world/store/slices/worldSlice";
import { selectors as MyLocationSelectors } from "./myLocationSlice";

export interface RoomActionPayload {
  floorId: number;
  roomAction: ra.RoomAction;
  parentWindow: WindowType; // for displaying error toasts
}

export interface SetRoomAccessModePayload {
  floorId: number;
  roomId: number;
  accessMode: AccessMode;
  accessModeDetails?: AccessModeDetails;
  parentWindow: WindowType;
}

export interface CycleRoomAccessModePayload {
  floorId: number;
  roomId: number;
  parentWindow: WindowType;
}

export interface AllFloorRoomsPayload {
  floorId: number;
  rooms: Array<Room>;
}

export interface NewRoomPayload {
  roomType: RoomType;
  floorId: number;
  seatAssignments?: RoomSeat[];
  noDefaultName?: boolean;
  disableCanarySFU?: boolean;
  parentWindow: WindowType;
  name?: string;
}

export interface MoveOrKnockPayload {
  floorId: number;
  roomId?: number;
  sectionNumber?: number;
  positionNumber?: number;
}

export interface ClickSeatPayload {
  floorId: number;
  roomId?: number; // undefined for reception
  positionNumber: number;
  sectionNumber?: number;
  parentWindow: WindowType;
}

export interface ClickRoomPayload {
  floorId: number;
  roomId: number;
}

export interface ClickReceptionPayload {
  floorId: number;
}

export interface RoomRect {
  roomId: number;
  clientRect: ClientRect;
}

const adapter = createEntityAdapter<Room>();

const applyAction = (state: Draft<EntityState<Room>>, action: ra.RoomAction) => {
  switch (action.actionType) {
    case "UPDATE_ROOM": {
      // Use setOne instead of upsertOne because upsertOne won't overwrite properties that are
      // set in the existing room, but undefined in the upserted room causing an incorrect state.
      adapter.setOne(state, action.room);
      break;
    }
    case "DELETE_ROOM": {
      break;
    }
    case "ADD_ROOM": {
      // Don't add the rooms until they have a valid id. Otherwise it'll
      // look like two rooms were added.
      if (action.room.id > 0) adapter.upsertOne(state, action.room);
      break;
    }
    case "ASSIGN_SEAT": {
      break;
    }
    case "UNASSIGN_SEAT": {
      break;
    }
    case "SET_STAGE_MODE": {
      const stage = state.entities[action.roomId]?.stage;
      if (stage) {
        const newStage: Stage = {
          ...stage,
          stageMode: action.stageMode,
        };
        adapter.updateOne(state, {
          id: action.roomId,
          changes: { stage: newStage },
        });
      }
      break;
    }
    case "SET_QA_MODE": {
      const stage = state.entities[action.roomId]?.stage;
      if (stage) {
        const newStage: Stage = {
          ...stage,
          QAMode: action.QAMode,
        };
        adapter.updateOne(state, {
          id: action.roomId,
          changes: { stage: newStage },
        });
        break;
      }
    }
  }
};

const slice = createSlice({
  name: "room",
  initialState: {
    rooms: adapter.getInitialState(),
    editingRoomId: undefined as number | undefined,
    hoveringRoomId: undefined as number | undefined,

    // Map rooms report their location when they're the room you're in
    currentRoomRect: undefined as RoomRect | undefined,
  },

  reducers: {
    // UI-initiated actions
    clientAction: (state, action: PayloadAction<RoomActionPayload>) => {
      applyAction(state.rooms, action.payload.roomAction);
    },

    setRoomAccessMode: (state, action: PayloadAction<SetRoomAccessModePayload>) => {},
    cycleRoomAccessMode: (state, action: PayloadAction<CycleRoomAccessModePayload>) => {},
    newRoom: (state, action: PayloadAction<NewRoomPayload>) => {},

    moveOrKnock: (state, action: PayloadAction<MoveOrKnockPayload>) => {},
    clickSeat: (state, action: PayloadAction<ClickSeatPayload>) => {},
    clickRoom: (state, action: PayloadAction<ClickRoomPayload>) => {},
    clickReception: (state, action: PayloadAction<ClickReceptionPayload>) => {},

    setCurrentRoomRect: (state, action: PayloadAction<RoomRect>) => {
      state.currentRoomRect = action.payload;
    },

    // Editing
    setEditingRoomId: (state, action: PayloadAction<number>) => {
      state.editingRoomId = action.payload;
    },
    clearEditingRoomId: (state) => {
      state.editingRoomId = undefined;
    },

    // prop optimization
    setHoveringRoomId: {
      reducer: (state, action: PayloadAction<{ roomId: number; isHovered: boolean }>) => {
        const payload = action.payload;
        if (payload.isHovered) {
          state.hoveringRoomId = payload.roomId;
        } else if (payload.roomId === state.hoveringRoomId) {
          state.hoveringRoomId = undefined;
        }
      },
      prepare: (payload: { roomId: number; isHovered: boolean }) => {
        return { payload, meta: { scope: "local" } };
      },
    },

    // Server messages
    requestFloor: (state, action: PayloadAction<number>) => {},
    setAllFloorRooms: (state, action: PayloadAction<AllFloorRoomsPayload>) => {
      const { floorId, rooms } = action.payload;
      const srvRoomIds = new Set(rooms.map((r) => r.id));
      const deletedRoomIds = [];
      for (const roomId of state.rooms.ids) {
        const room = state.rooms.entities[roomId];
        if (room?.floorId === floorId && !srvRoomIds.has(room.id)) {
          deletedRoomIds.push(roomId);
        }
      }
      adapter.removeMany(state.rooms, deletedRoomIds);
      adapter.upsertMany(state.rooms, rooms);
    },
    setRoom: (state, action: PayloadAction<Room>) => {
      // Use setOne instead of upsertOne because upsertOne won't overwrite properties that are
      // set in the existing room, but undefined in the upserted room causing an incorrect state.
      adapter.setOne(state.rooms, action.payload);
    },
    requestRoomsForAccount: (state, action: PayloadAction<number>) => {},
  },
});

export const { actions, reducer } = slice;

const adapterSelectors = adapter.getSelectors((state: RootState) => state.section.room.rooms);
export const selectors = {
  ...adapterSelectors,
  selectById: (id?: number) => (state: RootState) =>
    id ? adapterSelectors.selectById(state, id) : undefined,
  selectByIds: defaultMemoize(
    (roomIds: number[]) =>
      createSelector(
        (state: RootState) => state,
        (state) => {
          const rooms: { [roomId: number]: Room } = {};
          for (const roomId of roomIds) {
            const room = adapterSelectors.selectById(state, roomId);
            if (room) {
              rooms[roomId] = room;
            }
          }
          return rooms;
        }
      ),
    { equalityCheck: equal }
  ),
  selectByFloorId: defaultMemoize((floorId?: number) =>
    createDeepEqualSelector(adapterSelectors.selectAll, (rooms) =>
      rooms.filter((room) => floorId && room.floorId === floorId)
    )
  ),
  selectEditingRoomId: (state: RootState): number | undefined => state.section.room.editingRoomId,
  selectCurrentRoomRect: (state: RootState): RoomRect | undefined =>
    state.section.room.currentRoomRect,

  selectHoveringRoomId: (state: RootState): number | undefined => state.section.room.hoveringRoomId,
  selectIsMyRoomHovered: (roomId?: number) => (state: RootState) => {
    return roomId !== undefined && state.section.room.hoveringRoomId === roomId;
  },
  selectFloorMiniHoveredHeaderName: defaultMemoize(
    (floorId: number, isHovered: boolean, allowMiniMapNavigation: boolean) =>
      createSelector(
        (state: RootState) => state,
        (state) => {
          if (!isHovered) {
            return undefined;
          }
          const hoveredOccupantId = OccupantSelectors.selectHoveredOccupantId(state);
          if (hoveredOccupantId) {
            const occupant = OccupantSelectors.selectById(hoveredOccupantId)(state);
            if (occupant) {
              return occupant.name;
            }
          }
          if (!allowMiniMapNavigation) {
            return undefined;
          }
          if (state.section.room.hoveringRoomId) {
            const room = adapterSelectors.selectById(state, state.section.room.hoveringRoomId);
            if (!room || room.floorId !== floorId) {
              return undefined;
            }
            if (room.roomType === "Office" && (!room.name || room.name === "Office")) {
              const assignedSeats = room.assignedSeats ?? [];
              const myRoomId = MyLocationSelectors.selectMyRoomId(state);
              const isMyFloor = MyLocationSelectors.selectIsMyFloor(floorId)(state);
              const visibleToVisitors = myRoomId === room.id || isMyFloor;
              const currentOccupant = WorldSelectors.selectActiveOccupant(state);

              if (
                assignedSeats[0] &&
                assignedSeats.length === 1 &&
                (isMemberOrHasMemberAccess(currentOccupant) || visibleToVisitors)
              ) {
                return assignedSeats[0].name;
              }
            }

            return room.name;
          }
          return undefined;
        }
      ),
    { maxSize: 25 }
  ),
  hoveredRoomIsDndOnMyFloor: defaultMemoize((floorId: number) =>
    createSelector(
      (state: RootState) => state,
      (state) => {
        if (state.section.room.hoveringRoomId) {
          const room = adapterSelectors.selectById(state, state.section.room.hoveringRoomId);
          if (!room || room.floorId !== floorId) {
            return "NotDnd";
          }
          if (room.accessMode === "DoNotDisturb") {
            if (room.accessModeDetails?.dndReason === "Zoom") {
              return "Zoom";
            }
            return "Dnd";
          }
        }
        return "NotDnd";
      }
    )
  ),
};
