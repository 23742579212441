// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./reset.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./fonts.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./base.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./fullcalendar.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Use styleWithoutCustomScrollbar.css for websites (e.g., our marketing website) and style.css for apps, including web apps */\n", "",{"version":3,"sources":["webpack://roam/client/styles/styleWithoutCustomScrollbar.css"],"names":[],"mappings":"AAAA,8HAA8H","sourcesContent":["/* Use styleWithoutCustomScrollbar.css for websites (e.g., our marketing website) and style.css for apps, including web apps */\n@import \"reset.css\";\n@import \"fonts.css\";\n@import \"base.css\";\n@import \"fullcalendar.css\";\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
