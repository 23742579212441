/**
 * Defines the Location Types that exist in Roam.
 *
 * Primary: DefaultOnFloor, Reception, Room, Audience
 * Secondary: Stage, Backstage
 *
 * Each location has sufficient information to position an
 * occupant uniquely in this roam, on a floor, in a room,
 * in a position in that room. Various helper methods can extract
 * granular position data from a Location.
 *
 *
 */

import { z } from "zod";
import { AudienceSection, FloorSection } from "../helpers/sections";
import {
  firstBackstageIndex,
  firstFloorMicIndex,
  firstInvisibleObserverIndex,
  lastFloorMicIndex,
  lastInvisibleObserverIndex,
} from "./Room";
import { numberId, stringId } from "./zodTypes";

/**
 * The default location for an occupation on a floor.
 *
 * This is an "indirect" location, which is resolved server-side to figure out exactly where the
 * occupant should be placed.
 * It allows clients/callers/requesters to send an occupant to floor X, without needing to know
 * exactly where on that floor someone belongs.
 */
export const DefaultOnFloorLocation = z.object({
  kind: z.literal("DefaultOnFloorLocation"),
  occupantId: stringId(),
  section: FloorSection,
});
export type DefaultOnFloorLocation = z.infer<typeof DefaultOnFloorLocation>;

/**
 * Default Floor location for TeleRooms
 *
 * This is a location on a floor that is not shown in the UI so dormant TeleRooms do not
 * appear on the map and are not connected to any conversations (e.g. by being in reception)
 */

export const FloatingFloorLocation = z.object({
  kind: z.literal("FloatingFloorLocation"),
  occupantId: stringId(),
  section: FloorSection,
});
export type FloatingFloorLocation = z.infer<typeof FloatingFloorLocation>;

/**
 * A seat in reception. Once reception per floor, max.
 */
export const ReceptionLocation = z.object({
  kind: z.literal("ReceptionLocation"),
  occupantId: stringId(),
  section: FloorSection,
  positionNumber: numberId(),
});
export type ReceptionLocation = z.infer<typeof ReceptionLocation>;

/**
 * A location in a room.
 * Currently anyone in an auditorium and not in an
 * audience section is (should be) in a "StageLocation"
 * or a "BackstageLocation"
 *
 * Subclasses: StageLocation, BackstageLocation
 */
export const RoomLocation = z.object({
  kind: z.literal("RoomLocation"),
  subkind: z.enum([
    "RoomLocation",
    "StageLocation",
    "BackstageLocation",
    "FloorMicLocation",
    "InvisibleObserverLocation",
  ]),
  occupantId: stringId(),
  section: FloorSection,
  roomId: numberId(),
  positionNumber: numberId(),
});
export type RoomLocation = z.infer<typeof RoomLocation>;

/**
 * Any occupant in an auditorium
 * that is in a StageLocation is on stage
 *
 * The position number array is shared between stage and backstage
 * Because they're all in the same RoomLocation
 *
 * Superclass: RoomLocation
 */
export const StageLocation = z.object({
  kind: z.literal("RoomLocation"),
  subkind: z.literal("StageLocation"),
  occupantId: stringId(),
  section: FloorSection,
  roomId: numberId(),
  positionNumber: numberId(), // your position in the room (on stage or backstage)
});
export type StageLocation = z.infer<typeof StageLocation>;

/**
 * Any occupant in an auditorium
 * that is in a BackstageLocation is backstage
 *
 * The position number array is shared between stage and backstage
 * Because they're all in the same RoomLocation
 *
 * Superclass: RoomLocation
 */
export const BackstageLocation = z.object({
  kind: z.literal("RoomLocation"),
  subkind: z.literal("BackstageLocation"),
  occupantId: stringId(),
  section: FloorSection,
  roomId: numberId(),
  positionNumber: numberId(), // your position in the room (all are on stage now)
});
export type BackstageLocation = z.infer<typeof BackstageLocation>;

/**
 * An occupant in an auditorium
 * that is in a FloorMicLocation is in a special
 * pod that contains one floor mic.
 *
 * The lowest-index of this position is allowed to
 * talk and address the entire auditorium
 *
 * The position number array is shared with
 * stage and backstage
 */
export const FloorMicLocation = z.object({
  kind: z.literal("RoomLocation"),
  subkind: z.literal("FloorMicLocation"),
  occupantId: stringId(),
  section: FloorSection,
  roomId: numberId(),
  positionNumber: numberId(), // your position in the room (all are on stage now)
});
export type FloorMicLocation = z.infer<typeof FloorMicLocation>;

/**
 * Note: Currently, only recording bots are allowed to move to this location (see
 * NavigationRules.ts).
 *
 * An invisible location, only available in auditoriums and for use by recording bots.
 * An occupant in this location can see the stage and the audience (and hear the presenters + floor
 * mic) but are not in any particular pod (i.e. they have no direct conversations).
 *
 * Essentially, occupants here see the same thing as occupants in an AudienceLocation,
 * except that they aren't in a pod. (Note that other people in the auditorium DO get updates
 * indicating that they are there, though these may not be rendered.)
 *
 * Currently this is only used for recording bots, which need to record everything except
 * pod conversations.
 *
 * The position numbers are shared with stage, backstage, and floor mic
 */
export const InvisibleObserverLocation = z.object({
  kind: z.literal("RoomLocation"),
  subkind: z.literal("InvisibleObserverLocation"),
  occupantId: stringId(),
  section: FloorSection,
  roomId: numberId(),
  positionNumber: numberId(), // your position in the room (all are on stage now)
});
export type InvisibleObserverLocation = z.infer<typeof InvisibleObserverLocation>;

/**
 * A location in an audience. An audience is part of an Auditorium, which is
 * a type of room. The audience is divided into sections of size (100).
 * Each audience member is uniquely positioned by one section
 * and one position number within that section.
 *
 * The roomId and floorId are part of the audienceSection here
 * and not the AudienceLocation
 */
export const AudienceLocation = z.object({
  kind: z.literal("AudienceLocation"),
  occupantId: stringId(),
  section: AudienceSection,
  positionNumber: numberId(), // position within the section, NOT within the audience
});
export type AudienceLocation = z.infer<typeof AudienceLocation>;

export const Location = z.union([
  DefaultOnFloorLocation,
  FloatingFloorLocation,
  ReceptionLocation,
  RoomLocation,
  StageLocation,
  BackstageLocation,
  FloorMicLocation,
  InvisibleObserverLocation,
  AudienceLocation,
]);
export type Location = z.infer<typeof Location>;

export const ForceLeaveSectionReason = z.enum([
  "duplicateConnection",
  "kickedOffStage",
  "sectionDisappeared",
  "connectionError",
  "sectionDeleted",
  "ejected",
  "unknownConnection",
]);
export type ForceLeaveSectionReason = z.infer<typeof ForceLeaveSectionReason>;

export const locationsInSameRoomOrReception = (
  loc1: Location | undefined,
  loc2: Location | undefined
): boolean => {
  if (!loc1 || !loc2) {
    return false;
  } else if (loc1.kind === "ReceptionLocation" && loc2.kind === "ReceptionLocation") {
    return loc1.section.floorId === loc2.section.floorId;
  } else if (
    (loc1.kind === "RoomLocation" || loc1.kind === "AudienceLocation") &&
    (loc2.kind === "RoomLocation" || loc2.kind === "AudienceLocation")
  ) {
    return locationToRoomId(loc1) === locationToRoomId(loc2);
  } else {
    return false;
  }
};

export const locationInRoom = (location: Location, roomId: number): boolean => {
  if (location.kind === "AudienceLocation" && location.section.roomId === roomId) {
    return true;
  }
  if (location.kind === "RoomLocation" && location.roomId === roomId) {
    return true;
  }
  return false;
};

/**
 * Return a place in the auditorium.
 * One of : Stage, Backstage or Audience
 *
 * Returns undefined when input is unexpected or invalid
 *
 * @param location
 */
export const getPlaceInAuditorium = (
  location: Location | undefined
): "Stage" | "Backstage" | "FloorMic" | "Audience" | "InvisibleObserver" | undefined => {
  if (location?.kind === "RoomLocation") {
    if (location?.subkind === "StageLocation") {
      return "Stage";
    } else if (location?.subkind === "BackstageLocation") {
      return "Backstage";
    } else if (location?.subkind === "FloorMicLocation") {
      return "FloorMic";
    } else if (location?.subkind === "InvisibleObserverLocation") {
      return "InvisibleObserver";
    } else {
      return undefined;
    }
  } else if (location?.kind === "AudienceLocation") {
    return "Audience";
  } else {
    return undefined;
  }
};

export const locationToRoomId = (location: Location | undefined): number | undefined => {
  if (location?.kind === "AudienceLocation") {
    return location.section.roomId;
  }
  if (location?.kind === "RoomLocation") {
    return location.roomId;
  }
  return undefined;
};

export const locationToSectionNumber = (location: Location | undefined): number | undefined => {
  if (location?.kind === "AudienceLocation") {
    return location.section.sectionNumber;
  }
  return undefined;
};

export const locationToPositionNumber = (location: Location | undefined): number | undefined => {
  if (
    !location ||
    location.kind === "DefaultOnFloorLocation" ||
    location.kind === "FloatingFloorLocation"
  ) {
    return undefined;
  }
  return location.positionNumber;
};

export const createDefaultOnFloorLocation = (
  occupantId: string,
  floorId: number
): DefaultOnFloorLocation => {
  return {
    kind: "DefaultOnFloorLocation",
    occupantId,
    section: {
      kind: "floor",
      floorId,
    },
  };
};

export const createFloatingFloorLocation = (
  occupantId: string,
  floorId: number
): FloatingFloorLocation => {
  return {
    kind: "FloatingFloorLocation",
    occupantId,
    section: {
      kind: "floor",
      floorId,
    },
  };
};

export const createReceptionLocation = (
  occupantId: string,
  floorId: number,
  positionNumber?: number
): ReceptionLocation => {
  return {
    kind: "ReceptionLocation",
    occupantId,
    section: {
      kind: "floor",
      floorId,
    },
    positionNumber: positionNumber || 0,
  };
};

/**
 * Return a location in a room at a specified position or,
 * by default, position 0. If the Room is an Auditorium,
 * these positions are on stage or backstage.
 *
 * Notice the "subkind" field to allow StageLocation and
 * BackstageLocation
 */
export const createRoomLocation = (
  occupantId: string,
  floorId: number,
  roomId: number,
  positionNumber?: number,
  subkind?: "RoomLocation" | "StageLocation" | "BackstageLocation" | "FloorMicLocation"
): RoomLocation => {
  return {
    kind: "RoomLocation",
    subkind: subkind || "RoomLocation",
    occupantId,
    section: {
      kind: "floor",
      floorId,
    },
    roomId,
    positionNumber: positionNumber || 0,
  };
};

/**
 * Return a location on a stage at a specified position or,
 * by default, position 0. The codebase tries to enforce
 * the first numbered slots are stageLocations and the
 * rest are backstageLocations
 */
export const createStageLocation = (
  occupantId: string,
  floorId: number,
  roomId: number,
  positionNumber?: number // - optional position in the room, default 0. See above
): StageLocation => {
  if (positionNumber && positionNumber >= firstBackstageIndex) {
    positionNumber = 0;
  }
  return {
    kind: "RoomLocation",
    subkind: "StageLocation",
    occupantId,
    section: {
      kind: "floor",
      floorId,
    },
    roomId,
    positionNumber: positionNumber || 0,
  };
};

/**
 * Return a location on backstage at a specified position or,
 * by default, position 0. The codebase tries to enforce
 * the first numbered slots are stageLocations and the
 * rest are backstageLocations
 */
export const createBackstageLocation = (
  occupantId: string,
  floorId: number,
  roomId: number,
  positionNumber?: number // - optional position in the room, default firstBackstageIndex. See above
): BackstageLocation => {
  if (positionNumber && positionNumber < firstBackstageIndex) {
    positionNumber = firstBackstageIndex;
  }
  return {
    kind: "RoomLocation",
    subkind: "BackstageLocation",
    occupantId,
    section: {
      kind: "floor",
      floorId,
    },
    roomId,
    positionNumber: positionNumber || firstBackstageIndex,
  };
};

export const createFloorMicLocation = (
  occupantId: string,
  floorId: number,
  roomId: number,
  positionNumber?: number // - optional position in the room, default firstFloorMicIndex. See above
): FloorMicLocation => {
  if (
    positionNumber &&
    (positionNumber < firstFloorMicIndex || positionNumber > lastFloorMicIndex)
  ) {
    positionNumber = firstFloorMicIndex;
  }
  return {
    kind: "RoomLocation",
    subkind: "FloorMicLocation",
    occupantId,
    section: {
      kind: "floor",
      floorId,
    },
    roomId,
    positionNumber: positionNumber || firstFloorMicIndex,
  };
};

export const createInvisibleObserverLocation = (
  occupantId: string,
  floorId: number,
  roomId: number,
  positionNumber?: number // - optional position in the room, default firstInvisibleObserverIndex. See above
): InvisibleObserverLocation => {
  if (
    positionNumber &&
    (positionNumber < firstInvisibleObserverIndex || positionNumber > lastInvisibleObserverIndex)
  ) {
    positionNumber = firstInvisibleObserverIndex;
  }
  return {
    kind: "RoomLocation",
    subkind: "InvisibleObserverLocation",
    occupantId,
    section: {
      kind: "floor",
      floorId,
    },
    roomId,
    positionNumber: positionNumber || firstInvisibleObserverIndex,
  };
};

/**
 * Return a location in the audience at a specified position.
 * If section Number and/or position Number are not given
 * they default to 0.
 *
 * @param occupantId
 * @param floorId
 * @param roomId
 * @param sectionNumber -- the section in the audience starting at 0
 * @param positionNumber -- the position IN THE SECTION starting at 0
 */
export const createAudienceLocation = (
  occupantId: string,
  floorId: number,
  roomId: number,
  sectionNumber?: number,
  positionNumber?: number
): AudienceLocation => {
  return {
    kind: "AudienceLocation",
    occupantId,
    section: {
      kind: "audience",
      floorId,
      roomId,
      sectionNumber: sectionNumber || 0,
    },
    positionNumber: positionNumber || 0,
  };
};

/**
 * Returns whether or not [location] is in an auditorium.
 * Returns undefined if it is unknown whether or not this is (or resolves to) an auditorium
 * location.
 *
 * Annoyingly, be careful about using falsy checks with this method.
 */
export const isAuditoriumLocation = (location: Location): boolean | undefined => {
  switch (location.kind) {
    case "AudienceLocation":
      return true;
    case "ReceptionLocation":
      return false;
    case "RoomLocation":
      switch (location.subkind) {
        case "BackstageLocation":
        case "FloorMicLocation":
        case "InvisibleObserverLocation":
        case "StageLocation":
          return true;
        case "RoomLocation":
          return false;
        default:
          return undefined;
      }
    case "DefaultOnFloorLocation":
      return undefined;
    default:
      return undefined;
  }
};
