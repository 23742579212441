import ports from "../../common/ports.json";
import { allEnvironments, Environment } from "./environment";
import { ZoneType } from "./ZoneType";

/**
 * (URL) Locations of first-party Zone apps.
 *
 * Similar to serviceLocations.ts
 */

type Config = {
  zoneTypes: ZoneType[];
  port: number;
};

const CONFIGURATIONS: Record<string, Config> = {
  invites: {
    zoneTypes: ["interaction-buttons"],
    port: ports.ZONE_INVITE_APP,
  },
};

export const getZoneAppHostWildcard = (env: Environment) => {
  return getZoneAppHost("*")(env);
};

export const getZoneAppHost = (appKey: string) => (env: Environment) => {
  const config = CONFIGURATIONS[appKey];
  if (!config) return undefined;

  if (!config && appKey !== "*") {
    return undefined;
  }

  switch (env) {
    case "local": {
      let localHostname = "localhost";
      if (
        typeof window !== "undefined" &&
        window.location.href.startsWith("http") &&
        !window.location.hostname.includes(".")
      ) {
        localHostname = window.location.hostname;
      } else if (typeof process !== "undefined" && process.env.LOCAL_HOSTNAME) {
        localHostname = process.env.LOCAL_HOSTNAME;
      }
      return `http://${localHostname}:${config.port}`;
    }
    case "dev": // fallthrough
    case "exp": // fallthrough
    case "stage":
      return `https://${appKey}.zone.roam${env}.net`;
    case "prod":
      return `https://${appKey}.zone.roam.io`;
  }
};

/**
 * Given an [appKey, zoneType, environment], returns the location of the corresponding iframe, if any.
 * Returns undefined otherwise.
 *
 * For example:
 * getZoneAppLocation("invites")("interaction-buttons")("local")
 *
 * Note that this is a curried function.
 */
export const getZoneAppLocation =
  (appKey: string) => (zoneType: ZoneType) => (env: Environment) => {
    const config = CONFIGURATIONS[appKey];
    if (!config) {
      return undefined;
    }

    const zoneTypes = config.zoneTypes;
    if (!zoneTypes.includes(zoneType)) {
      return undefined;
    }

    const host = getZoneAppHost(appKey)(env);
    if (!host) return undefined;

    return `${host}/${zoneType}`;
  };

export const allZoneAppHosts: Record<Environment, string>[] = [];
for (const appKey of Object.keys(CONFIGURATIONS)) {
  const config = CONFIGURATIONS[appKey];
  if (!config) {
    continue;
  }

  // NOTE(vple): Not sure if I got the typing right here...
  const getHost = getZoneAppHost(appKey);
  const appHostDomains: { [key: string]: string | undefined } = {};
  for (const env of allEnvironments) {
    appHostDomains[env] = getHost(env);
  }
  allZoneAppHosts.push(appHostDomains as Record<Environment, string>);
}
export { ZoneType };
