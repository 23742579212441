import baseStyled, { ThemedStyledInterface, useTheme as baseUseTheme } from "styled-components";
import { makeBaseTheme } from "./baseTheme";
import { semanticColors } from "./colors";

// Do not export, as real theme should be created in ThemeProvider
const baseTheme = makeBaseTheme(semanticColors);

export const styled = baseStyled as ThemedStyledInterface<typeof baseTheme>;
export const useTheme = baseUseTheme as () => typeof baseTheme;
export { css, keyframes } from "styled-components"; // re-export action for convenience
export { ThemeProvider } from "./ThemeProvider";
export { ThemeProviderWithoutStore } from "./ThemeProviderWithoutStore";
