import React from "react";
import { styled } from "../../../../../../client/styles/theme";
import Button from "../../../../../../client/components/Button";
import { InviteSuggestion } from "../../../../../../shared/Models/RoamInvitations";
import InviteSuggestionBase from "./InviteSuggestionBase";

interface Props {
  suggestion: InviteSuggestion;
  onInvite?: (suggestion: InviteSuggestion) => void;
}

/**
 * Suggests a human that the user may want to invite to Roam.
 */
const InviteSuggestionCard: React.FC<Props> = (props) => {
  const { suggestion, onInvite = () => {} } = props;

  return (
    <Container>
      <InviteSuggestionBase suggestion={suggestion}>
        <Actions>
          <Button onClick={() => onInvite(suggestion)}>Invite</Button>
        </Actions>
      </InviteSuggestionBase>
    </Container>
  );
};
export default InviteSuggestionCard;

const Container = styled.div`
  padding: 0.75rem;
`;
Container.displayName = "Container";

const Actions = styled.div`
  align-self: center;
`;
Actions.displayName = "Actions";
