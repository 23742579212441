import { z } from "zod";

// Should be replaced at runtime with an implementation from client.ts or server.ts. The server.ts
// implementation will not compile client side since it relies on a node-specific package.
let getContext = (): LogContext | undefined => undefined;

export const setContextGetter = (getter: () => LogContext | undefined) => {
  getContext = getter;
};

export const ContextValueType = z.any();
export type ContextValueType = z.infer<typeof ContextValueType>;

export const ContextValue = z.object({
  key: z.string(),
  value: ContextValueType,
});
export type ContextValue = z.infer<typeof ContextValue>;

export const LogContext = z.record(ContextValue);
export type LogContext = z.infer<typeof LogContext>;

export const contextLogFields = (): Record<string, ContextValueType> => {
  const context = getContext();

  const result = {} as { [key: string]: ContextValueType };
  if (!context) {
    return result;
  }

  for (const [key, value] of Object.entries(context)) {
    result[key] = value.value;
  }

  return result;
};

export const contextWithValues = (
  values: { [key: string]: ContextValueType },
  context?: LogContext
): LogContext => {
  const newContext = { ...context };
  for (const [key, value] of Object.entries(values)) {
    if (value !== undefined) {
      newContext[key] = { key, value };
    } else {
      delete newContext[key];
    }
  }
  return newContext;
};

export const mergeContexts = (a: LogContext | undefined, b: LogContext | undefined): LogContext => {
  if (!a && !b) return {};
  if (a && !b) return a;
  if (b && !a) return b;
  return {
    ...a,
    ...b,
  };
};

export const getContextField = (field: string): ContextValueType | undefined => {
  return getContext()?.[field]?.value;
};

export { getContext };
