import { AsyncMethodReturns } from "penpal";
import React, { useEffect, useMemo, useState } from "react";
import { ParentZoneInteractionButtonApi } from "../../../../../client/zone/components/interactionButtons/ZoneInteractionButton";
import { InviteSuggestion, PendingInvitation } from "../../../../../shared/Models/RoamInvitations";
import { SendInviteRequest } from "../../../../../shared/SocialSearchMessages/SocialServerMessages";
import { ZoneResponderRequest } from "../../../../../shared/ZoneMessages/types";
import { logger } from "../../../../../shared/infra/logger";
import useChildPenpal from "../../../shared/useChildPenpal";
import ErrorPage from "./ErrorPage";
import SocialServerClient from "./SocialServerClient";
import SocialServerHttpClient from "./SocialServerHttpClient";
import ZonePlatformClient from "./ZonePlatformClient";
import ZonePlatformHttpApiClient from "./ZonePlatformHttpApiClient";
import InvitePopup from "./components/InvitePopup";

export type ToastStatus = "info" | "error";

interface Props {}

const InviteApp: React.FC<Props> = () => {
  const searchParams = new URL(window.location.href).searchParams;

  const { api: parentApi, apiStatus: parentApiStatus } =
    useChildPenpal<ParentZoneInteractionButtonApi>();

  const zoneToken: string | undefined = searchParams.get("token") ?? undefined;
  const [context, setContext] = useState<ZoneResponderRequest | undefined>(undefined);

  const personId: number | undefined = useMemo(() => {
    const requesterInfo = context?.requesterInfo;

    // Assumes that we only care about the first info.
    return requesterInfo?.[0]?.personId;
  }, [context]);

  const inviterEmail: string | undefined = useMemo(() => {
    const requesterInfo = context?.requesterInfo;

    // Assumes that we only care about the first info.
    return requesterInfo?.[0]?.email;
  }, [context]);

  useEffect(() => {
    const zonePlatformClient: ZonePlatformClient = new ZonePlatformHttpApiClient(zoneToken);
    zonePlatformClient
      .verifyToken()
      .then((response) => setContext(response))
      .catch((error) => logger.error({ error }));
  }, [zoneToken]);

  const [inviteSuggestions, setInviteSuggestions] = useState<InviteSuggestion[]>([]);
  const [pendingInvites, setPendingInvites] = useState<PendingInvitation[]>([]);
  const [remainingInvites, setRemainingInvites] = useState<number>(0);

  const client: SocialServerClient = useMemo(() => {
    return new SocialServerHttpClient(zoneToken);
  }, [zoneToken]);

  useEffect(() => {
    // Still loading (or some kind of error).
    if (!personId) {
      return;
    }

    client
      .fetchRemainingInvitations({ personId })
      .then((response) => setRemainingInvites(response.remainingInvitations))
      .catch((error) => logger.error({ error }));
    client
      .fetchInviteSuggestions({ personId })
      .then((response) => setInviteSuggestions(response.suggestions))
      .catch((error) => logger.error({ error }));
    client
      .fetchPendingInvitations({ personId })
      .then((response) => setPendingInvites(response.invitations))
      .catch((error) => logger.error({ error }));
  }, [personId, client]);

  if (!context || !personId) {
    return <></>;
  }

  // If iframed and API didn't load, fail.
  if (window.frameElement) {
    if (parentApiStatus === "loading") {
      return <>Loading</>;
    } else if (parentApiStatus !== "success") {
      return <ErrorPage />;
    }
  }
  // If API isn't loaded here it's because we're not iframed, which is likely because we're testing.
  // Use a stub that doesn't talk to anything.
  const api: AsyncMethodReturns<ParentZoneInteractionButtonApi> = parentApi ?? {
    showToast: async (message: string, status: ToastStatus) => {},
    openUrl: async (url: string) => {},
    close: async () => {},
    setSize: async (widthInPx: number, heightInPx: number) => {},
  };

  const sendInviteRequest = (request: SendInviteRequest, success?: () => void) => {
    return client
      .sendInvite(request)
      .then(() => {
        // Update the pending and remaining invitations to reflect the one we submitted.
        if (personId) {
          client
            .fetchRemainingInvitations({ personId })
            .then((response) => setRemainingInvites(response.remainingInvitations))
            .catch((error) => logger.error({ error }));
          client
            .fetchPendingInvitations({ personId })
            .then((response) => setPendingInvites(response.invitations))
            .catch((error) => logger.error({ error }));

          // Post to slack that an invite was sent.
          void fetch("https://hooks.zapier.com/hooks/catch/9800080/32apve6/", {
            method: "POST",
            body: JSON.stringify({
              inviterEmail: inviterEmail,
              inviteeEmail: request.invitee?.email,
              inviteeName: request.invitee?.name,
              inviteeCompany: request.invitee?.company,
            }),
          });
        }
      })
      .then(success)
      .catch((error) => {
        if (error.response.data.displayError) {
          return api.showToast(String(error.response.data.displayError), "info");
        }
        logger.error({ error });
        return api.showToast("Something went wrong!", "error");
      });
  };

  return (
    <InvitePopup
      personId={personId}
      inviteSuggestions={inviteSuggestions}
      pendingInvites={pendingInvites}
      remainingInvites={remainingInvites}
      sendInviteRequest={sendInviteRequest}
      close={() => api.close()}
    />
  );
};
export default InviteApp;
