import { css, styled } from "../../client/styles/theme";
import Button from "./Button";

/**
 * Usage example:
 *
 * <TabBar>
 *   <TabBar.Tab selected={index === 1} onClick={() => setIndex(1)}>
 *     One
 *   </TabBar.Tab>
 *   <TabBar.Tab selected={index === 2} onClick={() => setIndex(2)}>
 *     Two
 *   </TabBar.Tab>
 *   <TabBar.Tab selected={index === 3} onClick={() => setIndex(3)}>
 *     Three
 *   </TabBar.Tab>
 * </TabBar>
 */

const TabBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.background.secondary};
  border-radius: 1000rem;
  padding: 0.25rem;
  gap: 0.25rem;
`;
TabBar.displayName = "TabBar";

type TabProps = {
  selected?: boolean;
};

const Tab = styled(Button).attrs(({ selected }: TabProps) => ({
  variant: selected ? "solid" : "ghost",
  capsule: true,
}))<TabProps>`
  flex-grow: 1;

  ${({ theme, selected }) =>
    selected
      ? css`
          &:not(:disabled):hover,
          &:focus {
            background-color: ${theme.color.button.filled.background};
          }
        `
      : css`
          color: ${theme.color.text.secondary};
        `};
`;
Tab.displayName = "Tab";

export default Object.assign(TabBar, {
  Tab,
});
