/**
 * A Section is either a FloorSection or an AudienceSection
 * and is used to collect locations that are served by the same
 * section server. At any point in time, a section server
 * can handle one or more Sections. The grouping defined here
 * facilitates communication of information within Sections,
 * compartmentalized as such because they are managed by the same
 * server.
 */

import { z } from "zod";

/**
 * A type of Section, identified solely by FloorId
 * For now in an auditorium, all Stage and Backstage members
 * are in the same FloorSection (this may or may not change)
 *
 * Not to be confused with a section of an audience. That's actually
 * another type of Section: FloorSection and AudienceSection
 */
export const FloorSection = z.object({
  kind: z.literal("floor"),
  floorId: z.number().int().nonnegative(),
});
export type FloorSection = z.infer<typeof FloorSection>;

/**
 * A type of Section, identified by floorId, roomId and section number (within the audience,
 * not to be confused with *S*ection, which is a superclass of AudienceSection)
 */
export const AudienceSection = z.object({
  kind: z.literal("audience"),
  floorId: z.number().int().nonnegative(),
  roomId: z.number().int().nonnegative(),
  sectionNumber: z.number().int().nonnegative(),
});
export type AudienceSection = z.infer<typeof AudienceSection>;

/**
 * Either a FloorSection or an AudienceSection. These are the atomic units managed by section servers
 */
export const Section = z.discriminatedUnion("kind", [FloorSection, AudienceSection]);
export type Section = z.infer<typeof Section>;

/**
 * Given a section return a string of type
 * 'floor-FF' or 'audience-FF-RR-SS'
 *
 * @param section -- either an AudienceSection or a FloorSection
 *
 */
export const sectionToId = (section: Section): string => {
  if (section.kind === "floor") {
    const { floorId } = section;
    return `floor-${floorId}`;
  } else if (section.kind === "audience") {
    const { floorId, roomId, sectionNumber } = section;
    return `audience-${floorId}-${roomId}-${sectionNumber}`;
  } else {
    throw Error("sectionToId: invalid section");
  }
};

/**
 * Given the id number of a floor, return a string in the format
 * 'floor-NN' where NN  = floorId
 *
 * @param floorId the Id (int) of the floor
 */
export const floorToSectionId = (floorId: number): string =>
  sectionToId({ kind: "floor", floorId });

/**
 * Given the components of an audience section (which is a type of Section)
 * return a string Id in the form 'audience-FF-RR-SS' where
 * FF = floor number
 * RR = room number
 * SS = section number
 *
 * @param floorId -- int id of the floor
 * @param roomId -- int id of the room
 * @param sectionNumber -- int id of the section within the audience
 */
export const audienceSectionToSectionId = (
  floorId: number,
  roomId: number,
  sectionNumber: number
): string => sectionToId({ kind: "audience", floorId, roomId, sectionNumber });

/**
 * Given a string, return a section identified by that string
 * Currently parses: `floor-FF` and `audience-FF-RR-SS` where
 * FF = floor number
 * RR = room number
 * SS = section number within an audience
 */
export const idToSection = (id: string): Section | undefined => {
  if (!id || id.length === 0) return undefined;
  const parts = id.split("-");

  if (parts[0] === "floor") {
    if (parts.length !== 2 || !parts[1]) {
      return undefined;
    }
    const floorId = Number.parseInt(parts[1]);
    if (isNaN(floorId)) {
      return undefined;
    }
    const section: FloorSection = {
      kind: "floor",
      floorId,
    };
    return section;
  } else if (parts[0] === "audience") {
    if (parts.length !== 4 || !parts[1] || !parts[2] || !parts[3]) {
      return undefined;
    }
    const floorId = Number.parseInt(parts[1]);
    const roomId = Number.parseInt(parts[2]);
    const sectionNumber = Number.parseInt(parts[3]);
    if (isNaN(floorId) || isNaN(roomId) || isNaN(sectionNumber)) {
      return undefined;
    }
    const section: AudienceSection = {
      kind: "audience",
      floorId,
      roomId,
      sectionNumber,
    };
    return section;
  } else {
    return undefined;
  }
};
