import { allEnvironments, Environment, setOverrideEnvironment } from "./environment";
import { allZoneAppHosts } from "./zoneAppLocations";

/**
 * Overrides the environment for web-based Zone apps.
 *
 * A web app doesn't have access to Node's process, so we need a different way to determine the
 * environment.
 * The approach here is to take the current URL and reverse-match the environment that could have
 * generated it.
 *
 * This file is expected to be imported first so that it can override the environment before it is
 * used in any other file.
 *
 * Note that this is for Zone apps.
 * If you aren't developing a Zone app, you might be looking for `loadWebEnvironment.ts` instead.
 */

const environmentMatches: Set<Environment> = new Set();

for (const hosts of allZoneAppHosts) {
  for (const env of allEnvironments) {
    // Strip protocol
    const host = hosts[env]?.replace(/^https?:\/\//, "");
    if (!host) continue;

    if (window.location.host === host) {
      environmentMatches.add(env);
    }
  }
}

if (environmentMatches.size !== 1) {
  // Can't use other logger stuff, as it depends on environment
  console.log(
    `Unable to determine Zone app environment for ${
      window.location.host
    } with environmentMatches ${[...environmentMatches].join(",")}`
  );
} else {
  setOverrideEnvironment(environmentMatches.values().next().value as Environment);
}
