import { useEffect, useState } from "react";
import { AsyncMethodReturns, connectToParent } from "penpal";
import { logger } from "../../../shared/infra/logger";

// NOTE: Naming could probably be better (to not be penpal-dependent).
/**
 * As a child, connects to the parent window via penpal.
 *
 * Exposes an API object that allows calling async versions of the methods defined in `T`.
 * This is equivalent to `await connection.promise` as described in the penpal docs.
 *
 * Before (and after) the connection is loaded, this object is undefined.
 *
 * @see https://www.npmjs.com/package/penpal
 */
const useChildPenpal = <T extends object>(): {
  api: AsyncMethodReturns<T> | undefined;
  // TODO: Improve naming?
  /** The status of loading the API. */
  apiStatus: "loading" | "success" | "error";
} => {
  const [api, setApi] = useState<AsyncMethodReturns<T> | undefined>(undefined);
  const [status, setStatus] = useState<"loading" | "success" | "error">("loading");

  useEffect(() => {
    const connection = connectToParent<T>();
    connection.promise
      .then((api) => {
        setApi(api);
        setStatus("success");
      })
      .catch((error) => {
        logger.error({ error });
        setStatus("error");
      });

    return () => {
      setApi(undefined);
      // Change status?
      connection.destroy();
    };
  }, []);

  return { api, apiStatus: status };
};
export default useChildPenpal;
