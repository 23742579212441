const whiteColors = {
  white50: "rgba(255, 255, 255, 0.05)",
  white100: "rgba(255, 255, 255, 0.1)",
  white200: "rgba(255, 255, 255, 0.2)",
  white300: "rgba(255, 255, 255, 0.3)",
  white400: "rgba(255, 255, 255, 0.4)",
  white500: "rgba(255, 255, 255, 0.5)",
  white600: "rgba(255, 255, 255, 0.6)",
  white700: "rgba(255, 255, 255, 0.7)",
  white800: "rgba(255, 255, 255, 0.8)",
  white900: "rgba(255, 255, 255, 0.9)",
};

const blackColors = {
  black50: "rgba(0, 0, 0, 0.05)",
  black100: "rgba(0, 0, 0, 0.1)",
  black200: "rgba(0, 0, 0, 0.2)",
  black300: "rgba(0, 0, 0, 0.3)",
  black400: "rgba(0, 0, 0, 0.4)",
  black500: "rgba(0, 0, 0, 0.5)",
  black600: "rgba(0, 0, 0, 0.6)",
  black700: "rgba(0, 0, 0, 0.7)",
  black800: "rgba(0, 0, 0, 0.8)",
  black900: "rgba(0, 0, 0, 0.9)",
};

const greyColors = {
  grey50: "#FAFAFA",
  grey100: "#F5F5F5",
  grey200: "#EEEEEE",
  grey300: "#E0E0E0",
  grey400: "#BDBDBD",
  grey500: "#9E9E9E",
  grey600: "#757575",
  grey700: "#616161",
  grey800: "#424242",
  grey900: "#212121",
};

const citrusColors = {
  citrus50: "#F9FFF2",
  citrus100: "#F3FEE4",
  citrus200: "#E7FDC6",
  citrus300: "#DAFCA1",
  citrus400: "#CDFB72",
  citrus500: "#BEFA00",
  citrus600: "#AAE000",
  citrus700: "#93C200",
  citrus800: "#789E00",
  citrus900: "#557000",
};

const greenColors = {
  green50: "#E8F5E9",
  green100: "#C8E6C9",
  green200: "#A5D6A7",
  green300: "#81C784",
  green400: "#66BB6A",
  green500: "#4CAF50",
  green600: "#43A047",
  green700: "#388E3C",
  green800: "#2E7D32",
  green900: "#1B5E20",
};

const amberColors = {
  amber50: "#FFF8E1",
  amber100: "#FFECB3",
  amber200: "#FFE082",
  amber300: "#FFD54F",
  amber400: "#FFCA28",
  amber500: "#FFC107",
  amber600: "#FFB300",
  amber700: "#FFA000",
  amber800: "#FF8F00",
  amber900: "#FF6F00",
};

const pinkColors = {
  pink50: "#FCE4EC",
  pink100: "#F8BBD0",
  pink200: "#F48FB1",
  pink300: "#F06292",
  pink400: "#EC407A",
  pink500: "#E91E63",
  pink600: "#D81B60",
  pink700: "#C2185B",
  pink800: "#AD1457",
  pink900: "#880E4F",
};

const redColors = {
  red50: "#FFEBEE",
  red100: "#FFCDD2",
  red200: "#EF9A9A",
  red300: "#E57373",
  red400: "#EF5350",
  red500: "#F44336",
  red600: "#E53935",
  red700: "#D32F2F",
  red800: "#C62828",
  red900: "#B71C1C",
};

const indigoColors = {
  indigo50: "#F2EFFC",
  indigo100: "#E4DFF9",
  indigo200: "#CBC0F4",
  indigo300: "#B2A0F0",
  indigo400: "#9A7FEC",
  indigo500: "#835CE9",
  indigo600: "#6E31E7",
  indigo700: "#5700C9",
  indigo800: "#3E008E",
  indigo900: "#260058",
};

const blueColors = {
  blue50: "#E3F2FD",
  blue100: "#BBDEFB",
  blue200: "#90CAF9",
  blue300: "#64B5F6",
  blue400: "#42A5F5",
  blue500: "#2196F3",
  blue600: "#1E88E5",
  blue700: "#1976D2",
  blue800: "#1565C0",
  blue900: "#0D47A1",
};

const cyanColors = {
  cyan50: "#E0F7FA",
  cyan100: "#B2EBF2",
  cyan200: "#80DEEA",
  cyan300: "#4DD0E1",
  cyan400: "#26C6DA",
  cyan500: "#00BCD4",
  cyan600: "#00ACC1",
  cyan700: "#0097A7",
  cyan800: "#00838F",
  cyan900: "#006064",
};

const allColors = {
  white: "#FFFFFF",
  black: "#000000",
  ...whiteColors,
  ...blackColors,
  ...greyColors,
  ...citrusColors,
  ...greenColors,
  ...amberColors,
  ...pinkColors,
  ...redColors,
  ...indigoColors,
  ...blueColors,
  ...cyanColors,
};

const semanticTextAndIconColors = {
  primary: allColors.white,
  secondary: allColors.white500,
  disabled: allColors.white300,
  accentedPrimary: allColors.citrus500,
  error: allColors.red400,
  warning: allColors.amber500,
  positive: allColors.green500,
  active: allColors.citrus500,
  negative: allColors.red400,
  primaryOnLight: allColors.black,
  secondaryOnLight: allColors.black500,
};

const semanticBackgroundColors = {
  primary: allColors.white50,
  secondary: allColors.white100,
  elevated: allColors.grey800,
  overlayPrimary: allColors.black700,
  overlaySecondary: allColors.black300,
  primaryInverted: allColors.black700,
  primaryOnLight: allColors.black50,
  secondaryOnLight: allColors.black100,
  accentedPrimary: allColors.citrus500,
  visualization: allColors.indigo700,
};

const semanticBorderColors = {
  primary: allColors.white100,
  secondary: allColors.white300,
  focus: allColors.white,
  error: allColors.red400,
  accented: allColors.citrus500,
  positive: allColors.green500,
  negative: allColors.red400,
  primaryOnLight: allColors.black100,
  secondaryOnLight: allColors.black300,
};

export const semanticColors = {
  text: semanticTextAndIconColors,

  // Icons use the exact same semantic colors as text. I have just given them
  // their own named key to make it obvious that they can be used for icons
  // (rather than either just having a "text" key and hoping people realize
  // that they can be used for icons, or having an ugly key named "textIcon"
  // or "textAndIcon").
  icon: semanticTextAndIconColors,

  background: semanticBackgroundColors,
  border: semanticBorderColors,
};

export type SemanticColorsType = typeof semanticColors;

export default allColors;
