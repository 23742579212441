import React, { useId } from "react";
import { css, styled } from "../../client/styles/theme";
import IconCheckboxChecked from "../../icons/IconCheckboxChecked.svg";
import IconCheckboxUnchecked from "../../icons/IconCheckboxUnchecked.svg";
import Button from "./Button";

type InputProps = {
  capsule?: boolean;
  error?: boolean;
};

const Input = styled.input<InputProps>`
  ${({ theme }) => theme.font.subheadline};
  padding: 0.625rem 1rem;
  color: ${({ theme }) => theme.color.input.color};
  background-color: ${({ theme }) => theme.color.input.background};
  background-clip: padding-box;
  border: 0;
  border-radius: ${({ capsule }) => (capsule ? "1000rem" : "0.5rem")};
  color-scheme: dark;

  &::placeholder,
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.color.input.placeholderColor};
  }

  &:focus {
    background-color: ${({ theme }) => theme.color.input.focusBackground};
    outline: 0;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.input.disabledBackground};
    color: ${({ theme }) => theme.color.input.disabledColor};
    cursor: not-allowed;
  }

  ${({ theme, error }) =>
    error &&
    css`
      &,
      &:focus {
        outline: ${theme.dimen.border.default} solid ${theme.color.border.error};
      }
    `};

  &[type="checkbox"] {
    width: 1.25em;
    height: 1.25em;
    accent-color: ${({ theme }) => theme.color.text.primary};

    &:disabled {
      accent-color: ${({ theme }) => theme.color.input.disabledColor};
    }

    &:checked {
      accent-color: ${({ theme }) => theme.color.accent};
    }
  }

  &[type="radio"],
  &[type="color"] {
    width: 1.25em;
    height: 1.25em;
    appearance: none;
    background-color: transparent;
    background-clip: content-box;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: ${({ theme }) => `${theme.dimen.border.default} solid ${theme.color.border.focus}`};
    border-radius: 50%;
    padding: ${({ theme }) => theme.dimen.border.default};

    &:checked {
      background-color: ${({ theme }) => theme.color.accent};
      border-color: ${({ theme }) => theme.color.accent};
    }

    &:disabled {
      border-color: ${({ theme }) => theme.color.input.disabledColor};
    }

    &:disabled:checked {
      background-color: ${({ theme }) => theme.color.input.disabledColor};
      border-color: ${({ theme }) => theme.color.input.disabledColor};
    }
  }

  &[type="checkbox"],
  &[type="radio"],
  &[type="color"] {
    flex-shrink: 0;
  }

  &[type="range"] {
    accent-color: ${({ theme }) => theme.color.accent};
    padding: 0;
  }

  &[type="color"] {
    cursor: pointer;

    &,
    &::-webkit-color-swatch,
    &::-webkit-color-swatch-wrapper {
      border: none;
      border-radius: 50%;
      padding: 0;
    }
  }

  &[type="date"],
  &[type="datetime-local"] {
    ::-webkit-calendar-picker-indicator {
      /* Background image is encoded IconCalendarSmall.svg */
      background-image: ${({ theme }) =>
        `url("data:image/svg+xml,%3csvg fill='none' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='m4.5 2c-1.38071 0-2.5 1.11929-2.5 2.5v7c0 1.3807 1.11929 2.5 2.5 2.5h7c1.3807 0 2.5-1.1193 2.5-2.5v-7c0-1.38071-1.1193-2.5-2.5-2.5zm8.5 3.5h-10v-1c0-.82843.67157-1.5 1.5-1.5h7c.8284 0 1.5.67157 1.5 1.5zm-10 1h10v5c0 .8284-.6716 1.5-1.5 1.5h-7c-.82843 0-1.5-.6716-1.5-1.5z' fill='${theme.color.icon.primary.replace(
          "#",
          "%23"
        )}'/%3e%3c/svg%3e")`};
    }
  }

  &[type="time"] {
    ::-webkit-calendar-picker-indicator {
      /* Background image is encoded IconClockSmall.svg */
      background-image: ${({ theme }) =>
        `url("data:image/svg+xml,%3csvg fill='none' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='m8 2c3.3137 0 6 2.68629 6 6 0 3.3137-2.6863 6-6 6-3.31371 0-6-2.6863-6-6 0-3.31371 2.68629-6 6-6zm0 1c-2.76142 0-5 2.23858-5 5 0 2.7614 2.23858 5 5 5 2.7614 0 5-2.2386 5-5 0-2.76142-2.2386-5-5-5zm-.49847 2c.24546 0 .44961.17688.49194.41012l.00806.08988v2.5h1.49847c.27614 0 .5.22386.5.5 0 .24546-.17688.44961-.41012.49194l-.08988.00806h-1.99847c-.24546 0-.44961-.17688-.49195-.41012l-.00805-.08988v-3c0-.27614.22385-.5.5-.5z' fill='${theme.color.icon.primary.replace(
          "#",
          "%23"
        )}'/%3e%3c/svg%3e")`};
    }
  }
`;
Input.displayName = "Input";

const InputCheckboxInner = styled.div`
  display: flex;
  ${({ theme }) => theme.font.subheadline};
  padding: 0 0.25rem;

  ${Input} {
    appearance: none;
    display: none;
  }
`;
InputCheckboxInner.displayName = "InputCheckboxInner";

const InputCheckboxIcon = styled.label`
  color: ${({ theme }) => theme.color.border.focus};
  display: flex;
  font-size: 1.25em;

  ${Input}:not(:disabled):checked + & {
    color: ${({ theme }) => theme.color.accent};
  }

  ${Input}:not(:disabled) + & {
    cursor: pointer;
  }

  ${Input}:disabled + & {
    color: ${({ theme }) => theme.color.input.disabledColor};
    cursor: not-allowed;
  }
`;
InputCheckboxIcon.displayName = "InputCheckboxIcon";

type InputCheckboxProps = React.InputHTMLAttributes<HTMLInputElement>;
type InputCheckboxRef = React.Ref<HTMLInputElement>;

// Use for checkbox type inputs to display custom check and unchecked state icons
const InputCheckbox = React.forwardRef(
  (
    { id: providedId, type, checked, onChange, ...rest }: InputCheckboxProps,
    ref: InputCheckboxRef
  ) => {
    const internalId = useId();
    const id = providedId ?? internalId;

    return (
      <InputCheckboxInner>
        <Input ref={ref} type="checkbox" id={id} checked={checked} onChange={onChange} {...rest} />
        <InputCheckboxIcon htmlFor={id}>
          {checked ? <IconCheckboxChecked /> : <IconCheckboxUnchecked />}
        </InputCheckboxIcon>
      </InputCheckboxInner>
    );
  }
);

/**
 * Used to add small accessories, such as an icon or small button, inside of a text input.
 * A common example is a search box with a magnifying glass icon on the left and a clear button on the right.
 *
 * InputGroup is a label so clicking anywhere on the group will cause the input to become focused
 * (e.g., clicking on the magnifying glass accessory icon will focus the input field).
 *
 * Usage examples:
 * <InputGroup>
 *   <InputGroup.Accessory>
 *     <IconSearch />
 *   </InputGroup.Accessory>
 *
 *   <Input type="text" />
 *
 *   <InputGroup.Accessory>
 *     <Button variant="ghost" small>
 *       <Button.Icon>
 *         <IconDismissCircle />
 *       </Button.Icon>
 *     </Button>
 *   </InputGroup.Accessory>
 * </InputGroup>
 */
type InputGroupProps = {
  capsule?: boolean;
};

const InputGroup = styled.label<InputGroupProps>`
  ${({ theme }) => theme.font.subheadline};
  background-color: ${({ theme }) => theme.color.input.background};
  border: 0;
  border-radius: ${({ capsule }) => (capsule ? "1000rem" : "0.5rem")};
  display: flex;

  &:focus-within {
    background-color: ${({ theme }) => theme.color.input.focusBackground};
    outline: 0;
  }

  ${Input} {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    flex-grow: 1;
  }
`;
InputGroup.displayName = "InputGroup";

const InputGroupAccessory = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5em;
  padding: 0 1rem;

  &:first-child {
    margin-right: -1.375rem;

    > ${Button} {
      margin-left: -0.5rem;
    }
  }

  &:last-child {
    margin-left: -1.375rem;

    > ${Button} {
      margin-right: -0.5rem;
    }
  }

  > ${Button} {
    padding: 0.25rem;
  }
`;
InputGroupAccessory.displayName = "InputGroupAccessory";

const AssignedInputGroup = Object.assign(InputGroup, {
  Accessory: InputGroupAccessory,
});

export default Input;
export { InputCheckbox, AssignedInputGroup as InputGroup };
// Not meant to be used directly as a React component, but to be referenced by other styled-components
export { InputCheckboxInner };
