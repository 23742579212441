import React, { PropsWithChildren } from "react";
import { styled } from "../../../../../../client/styles/theme";
import ProfilePicture from "../../../../../../client/components/profile/ProfilePicture";
import { InviteSuggestion } from "../../../../../../shared/Models/RoamInvitations";
import { defaultOccupantPhotoUrl } from "../../../../../../shared/helpers/assets";
import { baseRoamURL } from "../../../../../../../common/api/http";

const MUTUAL_CONNECTIONS_PROFILE_LIMIT = 5;

interface Props {
  suggestion: InviteSuggestion;
}

/**
 * Base piece for inviting based on an invite suggestion.
 */
const InviteSuggestionBase = (props: PropsWithChildren<Props>) => {
  const { suggestion, children } = props;

  const { invitee, mutualConnectionCount } = suggestion;
  const mutualConnections = (suggestion.mutualConnections ?? [])
    .filter((connection) => connection.profilePhoto !== undefined)
    .slice(0, MUTUAL_CONNECTIONS_PROFILE_LIMIT);

  const image = invitee.profilePhoto ?? defaultOccupantPhotoUrl(baseRoamURL, "Member") ?? "";

  return (
    <Container>
      <ProfilePic imageSrc={image} displayName={"none"} />
      <Suggestion>
        <Name>{invitee.name}</Name>
        <Company>{invitee.company}</Company>
        <Connections>
          <ConnectionCount>{`${mutualConnectionCount} connection${
            mutualConnectionCount === 1 ? "" : "s"
          }`}</ConnectionCount>
          <ConnectionProfiles>
            {mutualConnections.map((connection) => (
              <ConnectionProfilePic
                key={connection.personId}
                // We filtered photos to be present, above
                imageSrc={connection.profilePhoto ?? ""}
                displayName={"none"}
              />
            ))}
          </ConnectionProfiles>
        </Connections>
      </Suggestion>
      <div>{children}</div>
    </Container>
  );
};
export default InviteSuggestionBase;

const Container = styled.div`
  // background-color: ${({ theme }) => theme.color.background.visualization};

  display: flex;
  flex-direction: row;
  column-gap: 0.75rem;
`;
Container.displayName = "Container";

const ProfilePic = styled(ProfilePicture)`
  align-self: center;

  width: 4rem;
  aspect-ratio: 1/1;
`;
ProfilePic.displayName = "ProfilePic";

const Suggestion = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: column;
`;
Suggestion.displayName = "Suggestion";

const Name = styled.div`
  ${({ theme }) => theme.font.headline};
`;
Name.displayName = "Name";

const Company = styled.div`
  ${({ theme }) => theme.font.subheadline};
`;
Company.displayName = "Roam";

const Connections = styled.div`
  ${({ theme }) => theme.font.caption};
`;
Connections.displayName = Connections;

const ConnectionCount = styled.div``;
ConnectionCount.displayName = ConnectionCount;

const ConnectionProfiles = styled.div`
  display: flex;
  flex-direction: row;
`;
ConnectionProfiles.displayName = ConnectionProfiles;

const ConnectionProfilePic = styled(ProfilePicture)`
  width: 1rem;
  aspect-ratio: 1/1;
`;
ConnectionProfilePic.displayName = "ConnectionProfilePic";

const Actions = styled.div`
  align-self: center;
`;
Actions.displayName = "Actions";
