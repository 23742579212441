import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal";
import { cloneDeep } from "lodash";
import { defaultMemoize } from "reselect";
import { baseRoamURL } from "../../../../../common/api/http";
import { defaultOccupantPhotoUrl } from "../../../../shared/helpers/assets";
import { isMemberOrRoamgineer, Occupant } from "../../../../shared/Models/Occupant";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux";
import { RootState } from "../../../store/reducers";

const adapter = createEntityAdapter<Occupant>();

// Do this on the client rather than the server. Server could send undefined
// fields which shouldn't overwrite any existing data.
const fillInDefaultPhotos = (state: EntityState<Occupant>, occupantIds: string[]) => {
  for (const occupantId of occupantIds) {
    const o = cloneDeep(state.entities[occupantId]);
    if (o && (!o.imageAbsoluteUrl || o.imageAbsoluteUrl.length === 0)) {
      o.imageAbsoluteUrl = defaultOccupantPhotoUrl(baseRoamURL, o.occupantType);
      adapter.upsertOne(state, o);
    }
  }
};

// Similarly, missing physical office data should mean that no office is assigned
// to the client, not to "not make a change".
const fillInPhysicalOffices = (state: EntityState<Occupant>, occupants: Occupant[]) => {
  for (const occupant of occupants) {
    const o = cloneDeep(state.entities[occupant.id]);
    if (o && (!occupant.physicalOfficeId || occupant.physicalOfficeId.length === 0)) {
      o.physicalOfficeId = undefined;
      adapter.upsertOne(state, o);
    }
  }
};

const slice = createSlice({
  name: "occupant",
  initialState: {
    occupants: adapter.getInitialState(),
    hoveredOccupantId: undefined as string | undefined,
  },
  reducers: {
    upsertOccupant: (state, action: PayloadAction<Occupant>) => {
      const occupant = action.payload;
      adapter.upsertOne(state.occupants, occupant);
      fillInDefaultPhotos(state.occupants, [occupant.id]);
      fillInPhysicalOffices(state.occupants, [occupant]);
    },
    upsertOccupants: (state, action: PayloadAction<Occupant[]>) => {
      const occupants = action.payload;
      const occupantIds = occupants.map((p) => p.id);
      adapter.upsertMany(state.occupants, occupants);
      fillInDefaultPhotos(state.occupants, occupantIds);
      fillInPhysicalOffices(state.occupants, occupants);
    },
    deleteOccupants: (state, action: PayloadAction<string[]>) => {
      const occupantIds = action.payload;
      adapter.removeMany(state.occupants, occupantIds);
    },

    requestAll: (_) => {},

    // prop optimization
    setHoveredOccupantId: {
      reducer: (state, action: PayloadAction<{ occupantId: string; isHovered: boolean }>) => {
        const payload = action.payload;
        if (payload.isHovered) {
          state.hoveredOccupantId = payload.occupantId;
        } else if (payload.occupantId === state.hoveredOccupantId) {
          state.hoveredOccupantId = undefined;
        }
      },
      prepare: (payload: { occupantId: string; isHovered: boolean }) => {
        return { payload, meta: { scope: "local" } };
      },
    },
  },
});

export const { reducer } = slice;
export const actions = {
  ...slice.actions,
};

const adapterSelectors = adapter.getSelectors((state: RootState) => state.world.occupant.occupants);
export const selectors = {
  ...adapterSelectors,
  selectById: (id?: string) => (state: RootState) =>
    id ? adapterSelectors.selectById(state, id) : undefined,
  selectByRoamId: defaultMemoize((roamId?: number) =>
    createDeepEqualSelector(adapterSelectors.selectAll, (occupants) =>
      occupants.filter((occupant) => occupant.roamId === roamId)
    )
  ),
  selectVisitors: createDeepEqualSelector(adapterSelectors.selectAll, (occupants) =>
    occupants.filter((occupant) => !isMemberOrRoamgineer(occupant))
  ),
  selectVisitorsPresent: createDeepEqualSelector(
    adapterSelectors.selectAll,
    (occupants) => occupants.filter((occupant) => !isMemberOrRoamgineer(occupant)).length > 0
  ),
  selectVisitorsByHostId: defaultMemoize((hostId: number) =>
    createDeepEqualSelector(adapterSelectors.selectAll, (occupants) =>
      occupants.filter((occupant) => occupant.hostId === hostId)
    )
  ),
  selectByPersonId: defaultMemoize((personId?: number) =>
    createSelector(adapterSelectors.selectAll, (occupants) =>
      personId ? occupants.find((o) => o.personId === personId) : undefined
    )
  ),
  selectByEmail: defaultMemoize((roamId?: number, email?: string) =>
    createSelector(adapterSelectors.selectAll, (occupants) =>
      roamId && email ? occupants.find((o) => o.roamId === roamId && o.email === email) : undefined
    )
  ),
  selectAllByEmail: defaultMemoize((roamId?: number, email?: string) =>
    createDeepEqualSelector(adapterSelectors.selectAll, (occupants) =>
      roamId && email ? occupants.filter((o) => o.roamId === roamId && o.email === email) : []
    )
  ),
  /**
   * Selects occupants with the given [ids], if they exist.
   * Returned as an object mapping occupant ID -> occupant.
   */
  selectByIds: defaultMemoize(
    (ids?: Array<string>) => {
      const idSet = new Set(ids || []);
      return createDeepEqualSelector(
        adapterSelectors.selectAll,
        (occupants): Record<string, Occupant> => {
          const occupantEntities: { [id: string]: Occupant } = {};
          for (const occupant of occupants) {
            if (idSet.has(occupant.id)) {
              occupantEntities[occupant.id] = occupant;
            }
          }
          return occupantEntities;
        }
      );
    },
    {
      equalityCheck: equal,
      maxSize: 50,
    }
  ),

  selectHoveredOccupantId: (state: RootState): string | undefined =>
    state.world.occupant.hoveredOccupantId,
};
export const OccupantSelectors = selectors;
