type Elevation = "shadow1" | "shadow2" | "shadow3" | "shadow4" | "shadow5" | "shadow6";

export const boxShadowStyles: {
  [elevation in Elevation]: string;
} = {
  shadow1: `box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 1px 2px 1px rgba(0, 0, 0, 0.1);`,
  shadow2: `box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 3px 6px 2px rgba(0, 0, 0, 0.1);`,
  shadow3: `box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3), 0px 4px 8px 4px rgba(0, 0, 0, 0.2);`,
  shadow4: `box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3), 0px 6px 10px 4px rgba(0, 0, 0, 0.2);`,
  shadow5: `box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.3), 0px 8px 10px 4px rgba(0, 0, 0, 0.2);`,
  shadow6: `box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.3), 0px 8px 12px 6px rgba(0, 0, 0, 0.2);`,
};

export const textShadowStyles: {
  [elevation in Elevation]: string;
} = {
  shadow1: `text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.1);`,
  shadow2: `text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 3px 6px rgba(0, 0, 0, 0.1);`,
  shadow3: `text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3), 0px 4px 8px rgba(0, 0, 0, 0.2);`,
  shadow4: `text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3), 0px 6px 10px rgba(0, 0, 0, 0.2);`,
  shadow5: `text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.3), 0px 8px 10px rgba(0, 0, 0, 0.2);`,
  shadow6: `text-shadow: 0px 1px 8px rgba(0, 0, 0, 0.3), 0px 8px 12px rgba(0, 0, 0, 0.2);`,
};

export const filterDropShadowStyles: {
  [elevation in Elevation]: string;
} = {
  shadow1: `filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1));`,
  shadow2: `filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3)) drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1));`,
  shadow3: `filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));`,
  shadow4: `filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.3)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.2));`,
  shadow5: `filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.3)) drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.2));`,
  shadow6: `filter: drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.3)) drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.2));`,
};
